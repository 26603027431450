/* Custom Code for NDUS */
@import 'cards-panels-boxes.less';
@import 'actions-list.less';
@import 'star-rating.less';


body {
  margin-top: 0!important;
}

.footer__group:first-of-type {
  display: none;
}

.ndus-guidance .btn{
  margin:0 .5rem .5rem 0
}

//#view-btr-link a.btn[onclick="goBack()"]{
//  .hidden();
//}


a[href^='/portal/controller/public/signUpForm/communityAccount/'] {
  .hide();
}

.has-float-label label {
  font-family: @font-family-base;
}


span.search-text-highligher {
  // using !important because the text color and bg color rely on each other
  background-color: #8DC63F!important;
  color: black !important;
  text-decoration: overline;
}



.header__left {
  display: flex;
  align-items: flex-start;
  flex: 1 1 90px;
}

.header__right {
  flex: 0 1 auto; // adjust for profile vs buttons
  //align-self: flex-end;
}

.header__logo-link > img {
  right: 0;
  position: static;
}

.ndus-icon-blue {
  fill: @brand-primary;
}
.ndus-icon-green {
    fill: @brand-secondary;
}



/* .header__main-wrap {justify-content: flex-start;} */

/* color swaps */

.btn-group.pull-right {
  flex: 1 1;
  /* float: none!important; */
  /* position: static; */
}

a#header-help-icon {
  flex: 0 1;
}

a#header-language-icon {
  float: right;
}

.main-search.show-in-header {
  margin: 0 1rem;
  background-color:transparent;
  //background-image: @brand-gradient-smooth;

  .row & {
    margin: 0 1rem;
  }
  
}
.header__logo-link > img {
  max-width:200px;
  //margin: 1rem 1.5rem;
}

@media (min-width: @screen-sm-min) {
  .header__right {
    align-items: flex-end;
  }
  .header__main-wrap {
    padding: 45px 0;
  }
}

@media (max-width: @screen-xs-max) {
  .header__main-wrap,.header__logo-link,.header__left,.header__right {
    flex-direction: column;
    align-items: center;
  }
  .logo_title{
    margin: 1rem 0 .5rem;
  }
  body {
    padding-top: 200px;
  }
}

@media (max-width: @screen-sm-max) {

  div.header__left a.header__logo-link {
    display: flex;
    //flex-direction: column;
    /* display: inline-block; */
    //align-content: space-between;
    align-items: baseline;
    justify-content: flex-start;
  }
  .header__main-wrap {
    //padding: 3rem 0;
    /* flex: 1 1; */
    height: unset;
  }
}

@media (max-width: 1480px) {
  div.header__left a.header__logo-link > img {
    display: none!important;
  }
  div.header__left a.header__logo-link > img.hidden-xs {
    display: inline-block !important;
    max-width:150px;
  }
}


/* === END CUSTOM === */


// Color testing
//#compliancePanel:after {
//  content: "test";
//  background: @gray-lightest;
//  width: 100%;
//  display: block;
//  padding: 1rem;
//}

/* dev highlights */
body.ndus-debug {
  /* dev only */
  h2.user-info__title {
    overflow-wrap: anywhere;
  }
  
  img[alt*="Image"] {
    outline: 2px dotted red;
    outline-offset: 2px;
    background-color: hotpink;
    opacity: .15;
    padding: .25rem;
    z-index: 1;
    display: block;
    overflow: visible;
    position: relative;
  }

  img[alt*="Image"]:after {
    content: attr(alt);
    background-color: var(--dark);
    font-family: 'Merriweather';
    font-size: 1rem;
    color: hotpink;
    display: block;
    width: 100%;
    height: 3rem;
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 99999;
    float: none;
    clear: both;
    overflow: visible;
    margin: 0;
    padding: 0;
  }

  .collapsing .nav {
    display: none;
  }


  // test
  .user-image__avatar {
    font-size: 16px;
    width: 3em;
    height: 3em;
    border-radius: 50%;
    //background: var(--gray);
    background-image: @brand-gradient;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .user-image__avatar::after {
    content: attr(alt);
    font-family: @font-family-serif;
    color: var(--light);
    display: inline-flex;
    border: 2px dashed red;
    height: 100%;
    width: 100%;
  }
}



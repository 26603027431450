/* Search criteria model selector css */
.search-criteria .pagination {
  margin: 5px 0 0 0;
}

.search-icon { color: @brand-highlight; }

.main-search {
  //background-color: @brand-primary;
  //background-image: -webkit-linear-gradient(rgba(0, 58, 134, 0.6), rgba(0, 58, 134, 0.6)), url("@{ra-image-path}socialss-search-bg.jpg");
  //background-image: linear-gradient(rgba(0, 58, 134, 0.6), rgba(0, 58, 134, 0.6)), url("@{ra-image-path}socialss-search-bg.jpg");

  //background-position: 50% 0;
  //background-size: cover;
  //padding: 2px 0 10px 0;
  //margin: 0 -15px 20px;
  //-webkit-box-shadow: none;
  //box-shadow: none;
  //text-align: center;
}

.main-search.show-in-header {
  //padding: 1rem;
  //margin: -114px 0 0 0;
  //background-color: @brand-primary;
  //background-image: none;
}
.main-search__form{
  //margin: 1.5rem 0;
  padding: 2rem 0;
  //width: 90%;
  max-width: @screen-sm;
  .container-fw();
  //.form-group();
}

.main-search.sl-header {
  background-color: @brand-primary;
  background-image: none;
}

.main-search.show-in-header.-docked {
  width: 100%;
  margin: 0;
}

.main-search.show-in-header.sl-header.-docked {
  padding: 8px 0 !important;
}

.main-search.show-in-header .main-search__form .main-search__input{ 
  //height: 40px 
}

.main-search__head {
  font-family: @font-family-sans-serif;
  font-size: 2em;
  font-weight: 600;
  color: var(--light);
  margin: 0;
  margin-top: 20px;
}
.main-search__subhead {
  font-family: @font-family-sans-serif;
  font-size: 1.4em;
  font-weight: normal;
  color: var(--light);
  margin-bottom: 10px;
}
.algolia-autocomplete {
  display: block!important;
 .pull-left();
  width: 80%;
  }

.main-search__input {
  .form-control();
  width: 100%;
  max-width: @screen-sm;
  color: @gray-dark;
}
.main-search__input.-error {
  box-shadow: 0px 0px 0px 4px @alert-danger-border; 
}

.main-search__button {
  font-size: 1.5rem;
  background-color: transparent;
  border: none;
  color: @brand-primary;
  width: 10%;
  max-height: 2.5rem;
  overflow: hidden;
  line-height: @line-height-small;

  span {
    vertical-align: middle;
    height: 1.5rem;
    display: inline-block;
  }
}
.main-search__button:hover {
  color: @brand-gray;
  text-decoration: none; }
.main-search__button-label {
  .hide-text();
}
.main-search .tt-menu,
.main-search .aa-dropdown-menu {
  //margin-left: 48px;
  width: 74vw;
  text-align: left; }
.main-search .tt-menu hr,
.main-search .aa-dropdown-menu hr {
  margin: 5px; }

.main-search.-docked {
  //position: fixed;
  top: 0;
  left: 0;
  right: 0;
  border-radius: 0;
  padding: 8px 0;
  margin-top: 0;
  margin-bottom: 0;
  background-image: none;
  /************
 * bootstrap z-indexes
 * bootstrap menu: 1000
 * bootstap modal: 1050
 ************/
  z-index: 1025; 
}
.main-search.-docked .main-search__head,
.main-search.-docked .main-search__subhead {
  display: none;
}
.main-search.-docked .main-search__form .main-search__input,
.main-search.-docked .main-search__form .main-search__button { 
  //height: 40px 
}

.live-search {
  position: relative;
  border-radius: 2px;
  border: none;
  background-color: var(--light);
  margin-top: 25px;
}
.live-search input {
  padding-left: 5px;
  padding-top: 8px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: solid 1px var(--gray-40);
  outline: 0;
}

#liveSearchSA {
  margin-left: 7.5px;
}

#liveSearchSS {
  margin-left: -7.5px;
}

.live-search .glyphicon-search {
  position: absolute;
  right: 12px;
  top: 10px;
  color: var(--gray-50);
}

#grid-search-box {
  height: 35px;
  border-radius: 4px;
  border: solid 1px var(--gray-50);
  z-index: revert;
  position: revert;
}

#grid-search-box-icon {
  cursor: pointer;
}



@media (min-width: 768px) {
  .main-search {
    //padding: 2px 0 73px 0;
    //border-radius: 0;
    //margin-right: 0;
    //margin-left: 0;
    //margin-top: -16px;
    //-webkit-transition: top 250ms ease;
    //transition: top 250ms ease; 
  }

  .main-search .main-search__form .main-search__input {
    //width: 48vw;
    //height: 54px;
  }

  .main-search__head {
    font-size: 2.9em;
    margin-top: 50px;
    margin-bottom: 5px;
  }
  .main-search__subhead {
    margin-bottom: 30px;
  }
  .main-search__button-label {
    //display: none; 
  }
  .main-search .tt-menu,
  .main-search .aa-dropdown-menu {
    width: 48vw;
  }
}

@media (max-width: 767px) {
  .main-search {
    margin-left: 0;
  }
  .main-search.-docked,
  .main-search.-show-in-header,
  .main-search.-sl-header {
    margin-left: -15px;
  }

  .main-search.sl-header.-docked {
    position: fixed;
  }
  .main-search.show-in-header.-docked {
    width: 65vw;
  }
  .main-search .main-search__form .main-search__input {
    //width: 80vw;
  }
  .main-search.show-in-header .main-search__form .main-search__input {
    margin-left: 0;
  }
  .main-search.sl-header .main-search__form .main-search__input {
    //width: 65vw;
    margin-left: 0;
  }
  //.main-search.show-in-header .main-search__form .main-search__input,
  //.main-search.show-in-header .main-search__form .main-search__button {
  //  z-index: 9999;
  //}
  .main-search.show-in-header.-docked .main-search__form .main-search__input {
    z-index: 9999;
  }
  .main-search.show-in-header.-docked form.main-search__form,
  .main-search.sl-header.-docked form.main-search__form {
    text-align: left;
  }
  .main-search .aa-dropdown-menu {
    width: 65vw;
  }
  .main-search.show-in-header .aa-dropdown-menu {
    margin-left: 0;
  }
  .main-search.sl-header .aa-dropdown-menu, .main-search.show-in-header.-docked .aa-dropdown-menu {
    margin-left: 0px;
    width: 65vw;
  }
  .algolia-autocomplete .aa-dropdown-menu {
    z-index: 1099 !important;
  }

  .container__post-discussion > .main-search.show-in-header.sl-header:not(.-docked),
  .container__view-discussion > .main-search.show-in-header.sl-header:not(.-docked),
  .container__discussion-denied > .main-search.show-in-header.sl-header:not(.-docked),
  .container__view-solution > .main-search.show-in-header.sl-header:not(.-docked),
  .container__solution-denied > .main-search.show-in-header.sl-header:not(.-docked),
  .container__solution-not-found > .main-search.show-in-header.sl-header:not(.-docked),
  .container__view-document > .main-search.show-in-header.sl-header:not(.-docked),
  .container__lookahead > .main-search.show-in-header.sl-header:not(.-docked),
  .container__view > .main-search.show-in-header.sl-header:not(.-docked),
  .container__titlepreferences > .main-search.show-in-header.sl-header:not(.-docked) {
    margin: 0 !important;
  }
}

@media (max-width: 383px) {
  .main-search.show-in-header.sl-header .main-search__input,
  .main-search.show-in-header.-docked .main-search__form .main-search__input {
    //width: 65vw;
  }
}



.container__post-discussion > .main-search.show-in-header.sl-header:not(.-docked),
.container__view-discussion > .main-search.show-in-header.sl-header:not(.-docked),
.container__discussion-denied > .main-search.show-in-header.sl-header:not(.-docked),
.container__view-solution > .main-search.show-in-header.sl-header:not(.-docked),
.container__solution-denied > .main-search.show-in-header.sl-header:not(.-docked),
.container__solution-not-found > .main-search.show-in-header.sl-header:not(.-docked),
.container__view-document > .main-search.show-in-header.sl-header:not(.-docked),
.container__lookahead > .main-search.show-in-header.sl-header:not(.-docked),
.container__view > .main-search.show-in-header.sl-header:not(.-docked),
.container__titlepreferences > .main-search.show-in-header.sl-header:not(.-docked) {
  margin-left: -15px;
  margin-right: -15px;
}
.dropdown-menu.-no-list {
  padding: 15px;
}

.dropdown-select {
  display: block;
  margin-bottom: 15px;
}
.dropdown-select__button {
  text-align: left;
  width: 100%;
  color: @gray-dark;
}
.dropdown-select__button:hover {
  color: @gray-dark;
  border: 1px solid var(--gray-20);
}
.dropdown-select__button:hover, .dropdown-select__button:focus, .dropdown-select__button:active, .dropdown-select__button:active:focus {
  background-color: transparent;
}
.dropdown-select__button > .caret {
  position: absolute;
  right: 15px;
  top: 20px;
}
.dropdown-select__value-label {
  display: block;
  margin-right: 25px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dropdown-select__arrow-sep {
  display: none;
}
.dropdown-select__img {
  float: left;
  width: 25px;
  margin-right: 5px;
  vertical-align: text-bottom;
}
.dropdown-select__img-label {
  margin-left: 30px;
  line-height: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dropdown-select.-lg .dropdown-menu {
  font-size: 18px;
}
.dropdown-select.-lg .dropdown-menu > li > a {
  width: 300px;
  padding: 7px 20px;
  white-space: normal;
}
@media (min-width: 768px) {
  .dropdown-select {
    display: inline-block;
  }
  .dropdown-select__button {
    max-width: 100%;
    width: 300px;
  }
  .dropdown-select__arrow-sep {
    display: inline-block;
    font-size: 28px;
    padding: 0 16px;
    vertical-align: middle;
  } }
.header {
  background-color: var(--light);
}

.header > .container {
  width: 100%;
}

.container-fluid {
   //background-color:var(--gray-05);
  //background-image: linear-gradient(to bottom, rgba(241, 243, 243, 0.0), rgba(241, 243, 243, 0.10), #F1F3F3FF, #F1F3F3FF, #F1F3F3FF, #F1F3F3FF, rgba(241, 243, 243, 0.02));
//background-color: @brand-gray;
}
.container-home, .container-fluid{
  background-color:var(--cool-gray-05);
}

@media (max-width: 767px) {
  .header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1025;
  } }
.header__main-wrap {
  height: 56px;
  padding: 5px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media (min-width: 768px) {
  .header {
    margin-bottom: 3px;
  }
  .header__main-wrap {
    padding: 20px 0;
  }
}

.header__logo-link {
  display: flex;
  align-items: baseline;
  text-decoration: none;
}

.header__logo-link > img {
  right: 160px;
  position: absolute;
}

@media (max-width: 800px) {
  div.header__left a.header__logo-link {
    display: none;
  }
}

@media (max-width: 1480px) {
  div.header__left a.header__logo-link > img {
    display: none;
  }
}

.header__left {
  -webkit-box-flex: 1;
  -ms-flex: 1 1;
  flex: 1 1;
}
.header__left > div.btn-group.pull-right.open {
  width: auto;
}
.header__login-wrap {
  padding: 5px 0;
}
.header__sub {
  background-color: var(--light);
  border-bottom:1px solid @brand-secondary;
}

.header__sub:before {
  content: '';
  display: block;
  position: relative;
  border-bottom: 1px solid @brand-primary;
  box-shadow: 0px 2px 6px rgba(0,0,0,0.6);
}

.header__sub > .container {
  width: 100%;
  margin-bottom: -2px;
  //background-image: @brand-gradient-smooth;
}

.header .user-image__avatar {
  border-radius: 50%;
}

a.login-btn:extend(.btn, .btn-xs ),
.login-btn:extend(.btn, .btn-xs ) {
  .button-variant(@btn-primary-color; @btn-primary-bg; @btn-primary-border);
}

.profile-button {
  position: relative;
  background-color: transparent;
  border: none;
  border-radius: 3px;
  padding: 0 3px;
}
@media (min-width: 768px) {
  .profile-button {
    padding: 3px 5px;
  } }
.profile-button:hover, .profile-button.-active {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.profile-button__text {
  float: left;
  padding-right: 8px;
  color: var(--gray-50);
  text-align: right;
  display: none;
}

.profile-button__text .-overflow {
  max-width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.profile-button__text .ln1 {
  color: var(--frost);
  padding-top: 5px;
  max-height: 50px;
}
.profile-button__text .ln2 {
  color: var(--gray-50);
  font-size: 14px;
  position: relative;
  top: -4px;
}
.profile-button .user-image {
  float: left;
  width: auto;
}
.profile-button .user-image__avatar {
  max-width: 35px;
}

.profile-menu {
  min-width: 250px;
  max-width: 300px;
  padding: 0;
  font-family: @font-family-base;
}

.profile-menu .text-muted {
  font-family: @font-family-base;
}
.profile-menu__wrap {
  padding: 15px;
}
.profile-menu > div.profile-menu__wrap {
  text-align: center;
}
.profile-menu__sep {
  border-top: 1px solid var(--gray-05);
}
.profile-menu__sep.-strong {
  border-width: 2px;
}
.profile-menu__title {
  margin: 10px;
  font-size: 15px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: @gray-dark;
}
.profile-menu__subtext {
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.2px;
  color: @gray-dark;
}
.profile-menu__form-label {
  margin: 0 7px 0 0;
  font-weight: normal;
}
.profile-menu .link-list > li > a {
  padding: 0 0 0 15px;
  color: @brand-primary;
  line-height: 40px;
}

.profile-menu .link-list > li > a:hover {
  color: @brand-highlight;
}
.profile-menu .link-list > li:last-child {
  border-radius: 0 0 4px 4px;
}
.profile-menu .-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
}
.profile-menu .-break {
  max-width: 300px;
  word-wrap: break-word;
}
.profile-menu::before {
  position: absolute;
  top: -7px;
  right: 12px;
  display: inline-block;
  border-right: 7px solid transparent;
  border-bottom: 7px solid var(--gray-light-c);
  border-left: 7px solid transparent;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  content: '';
}
.profile-menu::after {
  position: absolute;
  top: -6px;
  right: 13px;
  display: inline-block;
  border-right: 6px solid transparent;
  border-bottom: 6px solid var(--light);
  border-left: 6px solid transparent;
  content: '';
}
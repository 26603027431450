.star-rating {
  .glyphicon();
  background-image: none;
  white-space: nowrap;
  font-size: 1.25rem; // Ensure large enough for visibility
  position: relative;
  display: inline-block;
  letter-spacing: 4px;


  &::before {
    content: '\e007 \e007 \e007 \e007 \e007'; // 5 star outlines
    color: var(--light); // Gray color for empty stars
    //text-shadow: 1px 1px 2px @brand-primary; // Subtle shadow for clarity
  }

  //&::after {
  //  content: '\e007 \e007 \e007 \e007 \e007'; // 5 star outlines
  //  color: @brand-gray; // Gray color for empty stars
  //}

  .star-fill(@percent) {
    background: linear-gradient(90deg, @brand-primary @percent, @gray-light @percent);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-left: -50%;
  }

  // Modifier classes for different star levels
  &.-star1::after { .star-fill(20%);
    content: '\e006 \e007 \e007 \e007 \e007'; // 1 filled star
  }
  &.-star2::after { .star-fill(40%); 
    content: '\e006 \e006 \e007 \e007 \e007'; // 2 filled stars
  }
  &.-star3::after { .star-fill(60%); 
    content: '\e006 \e006 \e006 \e007 \e007'; // 3 filled stars
  }
  &.-star4::after { .star-fill(80%); 
    content: '\e006 \e006 \e006 \e006 \e007'; // 4 filled stars
  }
  &.-star5::after { .star-fill(100%); 
    content: '\e006 \e006 \e006 \e006 \e006'; // 5 filled stars
  }
}


@import (reference) "../bootstrap/mixins.less";
@import (reference) "../bootstrap/alerts.less";
// Sorry App - Status Plugin
.ndus-status-button {
  margin: 1rem 0;
}

.additionalNotices {
  padding-top: 1.25rem;
}

.sorry-status-notice-message {
  display: block;
}

//[class*=" sorry-status-notice-icon-"]:before,[class^=sorry-status-notice-icon-]:before {
//  font-family: status-bar-icons!important;
//  font-style: normal!important;
//  font-weight: 400!important;
//  font-variant: normal!important;
//  text-transform: none!important;
//  speak: none;
//  line-height: 1;
//  -webkit-font-smoothing: antialiased;
//  -moz-osx-font-smoothing: grayscale;
//  vertical-align: middle
//}

.sorry-status-notice-icon-times-circle:before {
  content: "\61"
}

//.sorry-status-notice-icon-bullhorn:before {
//  content: "\62"
//}

.sorry-status-bar {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  font-family: @font-family-base;
  font-size: 1rem;
  font-weight: 300;
  background-color: transparent!important;
  color: @text-color;
}

.sorry-status-bar-inner{
  
}

.sorry-status-notice {
  position: relative;
  padding: 12px 24px;
  background-color: var(--light);
}

.sorry-status-notice:first-child {
  padding-top: 24px
}

.sorry-status-notice:last-child {
  padding-bottom: 24px
}

.sorry-status-notice:after {
  visibility: hidden;
  display: block;
  clear: both;
  height: 0;
  font-size: 0;
  content: " "
}
.sorry-status-notice{
  .alert();
  .sorry-status-notice-header, .sorry-status-notice-text {
    color: @text-color;
  }
  .sorry-status-notice-content .btn-link {
    color: @link-color;
    padding: .5rem 1.5rem;
  }
}


.sorry-status-notice-unplanned {
  .alert-variant(@alert-danger-bg; @alert-danger-border; @alert-danger-text);
  .sorry-status-notice-header, .sorry-status-notice-text {
    color: @alert-danger-text;
  }
  .sorry-status-notice-content .btn-link {
    color: darken(@alert-danger-text, 10%);
    &:hover {
      color: darken(@alert-danger-text, 30%);
    }
  }
  
}


.sorry-status-notice-recovering, .sorry-status-notice-investigating, .sorry-status-notice-identified {
  .alert-variant(@alert-warning-bg; @alert-warning-border; @alert-warning-text);
  .sorry-status-notice-header, .sorry-status-notice-text {
    color: @alert-warning-text;
  }
  .sorry-status-notice-content .btn-link {
    color: darken(@alert-warning-text, 10%);
    &:hover {
      color: darken(@alert-warning-text, 30%);
    }
  }
}

.sorry-status-notice-planned, .sorry-status-notice-schedule {
  .alert-variant(@alert-info-bg; @alert-info-border; @alert-info-text);
  .sorry-status-notice-header, .sorry-status-notice-text {
    color: @alert-info-text;
  }
  .sorry-status-notice-content .btn-link {
    color: darken(@alert-info-text, 10%);
    &:hover {
      color: darken(@alert-info-text, 30%);
    }
  }
}

.sorry-status-notice-resolved, .sorry-status-notice-complete {
  .alert-variant(@alert-success-bg; @alert-success-border; @alert-success-text);
  .sorry-status-notice-header, .sorry-status-notice-text {
    color: @alert-success-text;
  }
  .sorry-status-notice-content .btn-link {
    color: darken(@alert-success-text, 10%);
    &:hover {
      color: darken(@alert-success-text, 30%);
    }
  }
}

.sorry-status-notice-identified {
  .alert-variant(@alert-danger-bg; @alert-danger-border; @alert-danger-text);
  .sorry-status-notice-header, .sorry-status-notice-text {
    color: @alert-danger-text;
  }
  .sorry-status-notice-content .btn-link {
    color: darken(@alert-danger-text, 10%);
    &:hover {
      color: darken(@alert-danger-text, 30%);
    }
  }
}

.sorry-status-notice-header,.sorry-status-notice-link,.sorry-status-notice-text {
  margin: 0;
  padding: 0
}

.sorry-status-notice-header {
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 18px
}

.sorry-status-notice-header .sorry-status-notice-icon {
  margin-right: 5px
}

.sorry-status-notice-content {
  padding-right: 24px;
  color: @gray-dark
}

.sorry-status-notice-text {
  margin-bottom: 1rem;
  white-space: wrap;
  overflow: visible;
  text-overflow: ellipsis;
  margin-left: 1.5rem;
}

.sorry-status-notice-subject {
  font-weight: 500;
  margin-right: 5px
}

.sorry-status-notice-schedule {
  opacity: .8;
  font-style: italic
}

.sorry-status-notice-link {
  display: inline-block;
  padding: 5px 10px;
  font-size: 14px;
  text-decoration: none;
  border: 1px solid #2980b9;
  border-radius: 4px;
  color: #2980b9;
  opacity: .7
}

.sorry-status-notice-link:active,.sorry-status-notice-link:hover,.sorry-status-notice-link:visited {
  text-decoration: none;
  opacity: 1
}

button.sorry-status-notice-close {
  position: absolute;
  right: 24px;
  font-size: 16px;
  color: @gray-dark;
  opacity: .2
}

button.sorry-status-notice-close:focus,button.sorry-status-notice-close:hover {
  text-decoration: none;
  cursor: pointer;
  opacity: .75
}

button.sorry-status-notice-close {
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: 0;
  -webkit-appearance: none
}

@media (min-width: 972px) {
  .sorry-status-notice {
    line-height:34px
  }

  .sorry-status-notice-content {
    margin: 0 auto;
    padding-right: 0;
    line-height: inherit;
    text-align: left;
  }

  .sorry-status-notice-details {
    display: block;
    max-width: 100%;
    white-space: wrap;
    overflow: visible;
    text-overflow: ellipsis;
    vertical-align: middle;
    padding-right: 1rem;
    //float: left;

  }

  .sorry-status-notice-text{
    white-space: wrap;
  }
  
  .sorry-status-notice-details,.sorry-status-notice-header,.sorry-status-notice-schedule {
    margin-right: 14px;
  }

  .sorry-status-notice-details * {
    display: inline
  }

  .sorry-status-notice-details .sorry-status-notice-header,
  .sorry-status-notice-text{
    display: block;
  }

  .sorry-status-notice-link {
    line-height: initial;
    max-width: 20%;
  }
  //.sorry-status-notice .btn {
  //  max-width: 20%;
  //  float: right;
  //}

  .sorry-status-notice-close {
    line-height: inherit
  }
}







//.sorry-status-notice {
//  line-height: normal !important;
//}
//
//div.sorry-status-bar {
//  margin-left: auto;
//  margin-right: auto;
//  background-color: var(--light);
//  display: none;
//}
//
//div.sorry-status-notice {
//  padding: 5px !important;
//  text-align: center;
//}
//
//button.sorry-status-notice-close {
//  margin-top: 12px;
//}
//
//div.sorry-status-notice-content {
//  padding: 0 12px;
//  text-align: left;
//}
//
//div.sorry-status-notice-details {
//  padding: 0;
//  margin: 0;
//  font-family: "Inter", sans-serif !important;
//  max-width: 80%;
//  display: inline-block;
//}
//
//time.sorry-status-notice-schedule {
//  display: block;
//  margin: 16px 0;
//  font-style: inherit;
//  font-weight: 500;
//  opacity: inherit;
//}
//
//h4.sorry-status-notice-header {
//  color: var(--dark);
//  display: inline-block;
//  padding: 10px;
//  border-radius: 6px;
//  margin: 5px 5px 5px 0;
//  min-width: 200px;
//  font-family: inherit;
//}
//
//p.sorry-status-notice-text {
//  white-space: normal;
//  overflow-wrap: inherit;
//  margin: 0;
//  hyphens: auto;
//  font-family: inherit;
//  display: inline-block;
//}
//
//@media (min-width: 768px){
//  p.sorry-status-notice-text{
//    display: inline;
//  }
//}
//
//strong.sorry-status-notice-subject {
//  white-space: normal;
//  overflow-wrap: break-word;
//  margin: 0;
//  display: inline;
//  font-family: inherit;
//  font-weight: 700;
//}
//
//div.sorry-status-notice-planned > div.sorry-status-notice-content > div.sorry-status-notice-details > h4.sorry-status-notice-header {
//  background-color: #a8aaeb;
//}
//
//div.sorry-status-notice-investigating > div.sorry-status-notice-content > div.sorry-status-notice-details > h4.sorry-status-notice-header {
//  background-color: #f3c301;
//}
//
//div.sorry-status-notice-identified > div.sorry-status-notice-content > div.sorry-status-notice-details > h4.sorry-status-notice-header {
//  background-color: #f07575;
//}
//
//div.sorry-status-notice-recovering > div.sorry-status-notice-content > div.sorry-status-notice-details > h4.sorry-status-notice-header {
//  background-color: #8dc63f;
//}
//
//a.sorry-status-notice-link {
//  /* max-width: 50%;
//  margin-top: 16px;
//  border-radius: 0.25em;
//  border: 3px solid rgb(128, 189, 1) !important;
//  color: var(--light);
//  opacity: 1;
//  float: right; */
//  font-size: 14px;
//  float: right;
//  width: 82px !important;
//  height: 41px;
//  padding: 7px;
//  min-height: unset;
//  opacity: 1;
//}

//a.sorry-status-notice-link.cts-button::after{
//  top: 165%;
//  left: -3%;
//  width: 134%;
//  height: 165%;
//}
//
//.additionalNotices {
//  display: none;
//}
//
//div.viewMore {
//  text-align: center;
//  color: var(--dark);
//  font-size: 14px;
//  margin-top: 12px;
//}
//
//button.viewMoreButton {
//  background: none;
//  border: none;
//}
//
//h2.status-all-systems-oper {
//  background-color: #8dc63f;
//  color: var(--dark);
//  border-radius: 6px;
//  padding: 15px 20px;
//  font-size: 26px;
//  text-align: center;
//  margin-top: 0;
//  margin-bottom: 0;
//  display: inline-block;
//  /* min-width: 512px; */
//}
//
//div.all-sys-oper {
//  text-align: center;
//  display: none;
//}
//
//div.status-loading{
//  text-align: center;
//  font-size: 36px;
//}
//
//.status-section>h2{
//  text-align: center;
//  border: none;
//}
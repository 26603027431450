.navbar {
  margin-bottom: 0;
  min-height: 0;
  color: #151515;}

.navbar-toggle {
  background-color: transparent;
  border-color: #889DAF;
  padding: 6px;
  margin-bottom: 14px;
}
.navbar-toggle:hover {
  border-color: @brand-primary;
  background-color: var(--cool-gray-05); }
.navbar-toggle .icon-bar {
  width: 18px;
  background-color: @brand-primary; 
}
@media (max-width: 767px) {

  .navbar-nav > li {
    display: flex;
  }
  .navbar-nav > li {
    font-size: 1.5rem;
    width: 100%;
    align-self: flex-end;
    border-image-slice: 1;
    border-bottom-width: 3px;
    border-bottom-style: solid;
    border-image-source: @brand-gradient-centered;

    position: relative;
    display: inline-block;
    overflow: hidden;
    transition: color 0.4s ease;
  }
  
  .navbar-nav > li > a{
    padding: 1.5rem;
  }
    
  .navbar-nav > li > a::before {
    content: '';
    position: absolute;
    top: -100%;
    right: -100%;
    height: 100%;
    width: 200%;
    background-image: @brand-gradient-blue;
    transition: right 0.4s ease;
    z-index: -1;
  }
  .navbar-nav > li > a:hover::before {
    right:0;
    top:0;
  }

  .navbar-nav > li > a:hover {
    
  }


  .navbar-nav > li > a:hover, .navbar-nav > li > a:active, .navbar-nav > li > a:focus {
    color:var(--light);
    z-index: 1;
    border-image: none;



  }
  

  .navbar-collapse {
    background-color: var(--cool-gray-05);
    margin-right: -15px;
    margin-left: -15px;
    border-top: 1px solid var(--gray-20); }
  .profile-menu_KB {
    width: 100%;
    padding: 0;
    position: inherit;
    margin-bottom: 15px;
  }
  .quick-links-menu {
    width: 100%;
    position: inherit;
    background-color: var(--light) !important;
  }
  #mainNavCollapse {
    height: calc(100vh - 120px);
    // COLORWASH VERSION
    //background-image: @brand-gradient;
  
  }
}
@media (min-width: 768px) {
  .navbar-nav.-main > li > a:first-child {
    margin-left: -5px; }
  .navbar-nav.-other > li > a {
    padding: 5px; }
  .navbar-nav > li > a {
    padding: 8px 25px;
    color: inherit; }
  .navbar-nav > li > a:hover, .navbar-nav > li > a:active, .navbar-nav > li > a:focus {
    background-color: transparent;
    color: @brand-highlight;
  }
  .navbar-collapse{
    padding: 0; 
  }
  .profile-menu_KB {
    min-width: 250px;
    max-width: 300px;
    padding: 0;
  }
  .quick-links-menu {
    width: 280px;
  }

  .navbar-nav > li:hover {
    //border-bottom:3px solid #8DC63F;
    box-shadow: inset 0px -4px 0px 0px @brand-secondary;

  }
}

#mainNavCollapse.collapsing *{
  display: none;

}

.navbar-nav > li {
  //font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  //line-height: 1.43;
  //text-align: center;
  color: @brand-primary;
  //padding-top: 1rem;
  //padding-bottom:4px;

  //border-bottom: 4px solid @brand-gray;
}




.navbar-nav > li > a {
  // COLORWASH VERSION
  //color: var(--light);
  // BASIC VERSION
  color: @brand-primary;
}

@media (min-width: 768px) {
  .navbar-nav > li:first-child {
    margin-left: -16px;
    padding-left: 16px;
    padding-right: 14px;
  }

  .navbar-nav > li > a {
    color: @brand-primary;
  }
 
  
  
}

@media (max-width: @grid-float-breakpoint-max) {

  .navbar-nav > li.active > a::before {
    background-image:none;
    background-color: @brand-primary;
  }
  
  
  .navbar-nav > li.active {
    border-image-source: @gray-gradient;
  }

  .navbar-nav > li.active > a {
    color:@brand-gray;
  }

  .navbar-nav > li.active > a:hover, .navbar-nav > li.active > a:active {
  color:var(--light);
  }
  
  
  
}


@media (min-width: @grid-float-breakpoint) {

  .navbar-nav > li.active {
    color: @brand-primary;
    box-shadow: inset 0px -4px 0px 0px @brand-primary;
  }
}


button.navbar-toggle {
  background-color: white !important;
}

button.navbar-toggle:hover {
  background-color: var(--blue-05) !important;
  
}

.nav-pills > li.active > a,
.nav-pills > li.active > a:focus,
.nav-pills > li.active > a:hover {
  background-color: @brand-primary;
}
.actions-list {
  list-style: none;
  margin: 0;
  padding: 5px 0;
}

.actions-list__item {
  position: relative;
  display: inline-block;

  & > .actions-list__link {
    text-decoration: none;
    &:extend(.btn);
    &:extend(.btn-sm);
    .button-variant(@brand-primary, transparent, @gray-lighter);

    &:hover, &:focus, &:active {
      text-decoration: none;
      //color: var(--light);
      //background-color: @cool-gray-05;
      //background-image: @brand-gradient-smooth;
 


    }

    > .actions-list__icon {
      margin-right: 2px;
      color: @brand-secondary;

      &:hover, &:focus, &:active {
        color: @brand-primary;
      }
    }

    &.-share > .actions-list__icon {
      color: var(--tomato);
    }

    &.-copy > .actions-list__icon {
      color: var(--sherbert);
    }

    &.-favorite > .actions-list__icon {
      color: var(--state-warning-text);
      top: 2px;
    }

    &.-pdf > .actions-list__icon {
      color: var(--red-50);
    }

    &.-print > .actions-list__icon {
      color: var(--gray);
    }

    &.-author > .actions-list__icon {
      color: var(--blurple-bold);
    }

    &.-comment > .actions-list__icon {
      color: var(--purple-70);
      top: 3px;
    }

    &.-back > .actions-list__icon {
      color: @brand-primary;
    }
  }
}

.dropdown-menu .actions-list__link {
  text-decoration: none;

  &:hover, &:focus, &:active {
    background-color: @gray-lighter;

    .actions-list__icon {
      color: var(--dark);
    }
  }
}

.dropdown-menu .actions-list__icon {
  width: 1rem;
}

.actions-list__feedback {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10;
  display: none;
  padding: 5px 10px;
  margin-top: 5px;
  background-color: var(--light);
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.176);
  color: #777;
  font-size: 1em;
  min-width: 200px;
}

.actions-list .glyphicon, .actions-list__icon {
  background: @brand-gradient-min;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.actions-list__link:hover .glyphicon,
.actions-list__link:focus .glyphicon,
.actions-list__link:active .glyphicon,
.actions-list__link:hover.-share > .actions-list__icon,
.actions-list__link:focus.-share > .actions-list__icon,
.actions-list__link:active.-share > .actions-list__icon {
  //background: var(--light);
  //-webkit-background-clip: text;
  //-webkit-text-fill-color: transparent;
  
}

.shortcut-panel {
  margin-bottom: 2.5rem;
}


.shortcut-panel__title {
  //font-family: @font-family-sans-serif;
  font-weight: normal;
  font-size: 1.5rem;
  margin-bottom: 15px;


}

.shortcut-panel__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.shortcut-panel__item {
  padding: 5px;
  margin-bottom: 20px;
  width: 48.4%;
  border-radius: 4px;
  border: 1px solid var(--gray-05);
  background-color: var(--light);
  box-shadow: 0px 5px 5px rgba(0,0,0,0.08);
}
.shortcut-panel__item:hover{
  box-shadow: none;
}

.shortcut-panel__item:hover .shortcut-panel__link, .shortcut-panel__item:active .shortcut-panel__link, .shortcut-panel__item:focus .shortcut-panel__link,
.shortcut-panel__item:hover .shortcut-panel__label, .shortcut-panel__item:active .shortcut-panel__label, .shortcut-panel__item:focus .shortcut-panel__label {
  text-decoration: underline;
  color: @brand-lowlight;
}

.shortcut-panel__link {
  height: 100%;
  text-decoration:none;
}
.shortcut-panel__link:hover, .shortcut-panel__link:active, .shortcut-panel__link:focus {
  text-decoration: underline;
  outline: none;
}

.shortcut-panel__label {
  width: 100%;
  text-align: center;
  line-height: 1.2;
  font-size: 1.5em;
  padding-top: 15px;
  color: @brand-primary;
}

.shortcut-panel__subtext {
  font-size: .7em;
  color: var(--gray-50);
  padding-top: 5px;
}

.shortcut-panel__icon {
  height: 150px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid var(--gray-20);
  margin-left: -5px;
  margin-right: -5px;
}
.shortcut-panel__icon img {
  max-width: 110px;
  max-height: 110px;
}
.shortcut-panel__icon.-font {
  text-align: center;
  font-size: 6rem;
}

.shortcut-panel + .shortcut-panel::before {
  position: relative;
  width: 100%;
  height: 1px;
  margin: 20px auto;
  background-color: var(--gray-05);
  display: block;
}

@media (min-width: 768px) {
  .shortcut-panel__item {
    padding: 15px 20px;
    width: 24.2%;
  }
  .shortcut-panel__label {
    font-size: 1.5em;
  }
  .shortcut-panel__icon {
    height: 150px;
    margin-left: -20px;
    margin-right: -20px;
  }
  .shortcut-panel__icon img {
    max-width: 110px;
    max-height: 110px;
  }
  .shortcut-panel__icon.-font {
    font-size: 8rem;
  }
}



.row--cards {
  display: flex;  // Make row a flex container to ensure columns stretch
  flex-wrap: wrap;  // Ensure wrapping behavior if needed
  margin-left: -11px;  // Offset the padding on the first column
  margin-right: -11px;  // Offset the padding on the last column
}

.card__col {
  .make-xs-column(6);
  .make-lg-column(3,1.5rem);
  display: flex;  // Ensure columns stretch to full height
  margin-bottom: 1rem;
}

.card {
  background-color: var(--light);
  border: 1px solid var(--gray-light-d);
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  padding: 1rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 100%;
  height: 100%;

  display: flex;  // Make the card a flex container
  flex-direction: column;  // Stack contents vertically
  justify-content: space-between;
  text-align: left;  // Ensure all content is left-aligned
}


.card-header {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  min-height: 2.5rem;
}

.card, .card:hover, .card:active, .card:focus {
  & a {
    text-decoration: none;
  }
}

.card-icon {
  font-size: 1.5rem;
  color: @brand-primary;
  margin-right: .5rem;  // Space between icon and title
}

.card-icon .ndus-logo-graphic {
  width: 1.5rem;
  height: 1.5rem;
}

.card-title {
  font-size: 1rem;
  font-weight: bold;
}

.card-subtext {
  font-size: 14px;
  color: @brand-gray;
  margin-top: 10px;  // Ensure it stays on its own row with space above
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(255, 255, 255, 0.3);
}

/* General improvements for the contact section */
.ndus-service-desk__contact p {
  font-size: 1.1em;
  margin-bottom: 1.5rem;
  display: flex; /* Use flexbox for better alignment */
  align-items: center; /* Align icons with text */
}

/* Styling for Font Awesome icons */
.ndus-service-desk__contact .fa {
  font-size: 1.25em;
  margin-right: 0.5rem;
  color: @brand-gray; /* Optional: Add color to icons */
}

/* Styling for the hours section */
.ndus-service-desk__hours {
  margin-top: 1rem;
}

.ndus-service-desk__hours-list {
  padding-left: 0; /* Remove default padding */
  list-style: none; /* Remove bullet points */
  margin-top: 0.5rem;
}

.ndus-service-desk__hours-list li {
  position: relative; /* Position for pseudo-element */
  padding-left: 1.75rem; /* Offset the text to make room for custom bullet */
  margin-bottom: 0.5rem; /* Spacing between list items */
}


/* Mobile responsive adjustments */
@media (max-width: 768px) {
  .ndus-service-desk__contact p {
    font-size: 1em;
  }

  .ndus-service-desk__contact .fa {
    font-size: 1.2em;
    margin-right: 0.4rem;
  }

  .ndus-service-desk__hours-list li {
    padding-left: 1.5rem;
  }
}
/* Styling for the definition list */
.ndus-service-desk__hours-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

/* Styling for the definition terms */
.ndus-service-desk__hours-list dt {
  font-weight: bold;
  color: @gray-dark;
  margin-top: 10px;
}

/* Styling for the definition descriptions */
.ndus-service-desk__hours-list dd {
  margin-left: 0;
  padding-left: 20px;
  color: @brand-gray;
}
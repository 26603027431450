/* Customized Right Answers CSS */
@charset "UTF-8";
@import (reference) '../bootstrap.less';
@import 'header.less';
@import 'search.less';
@import 'announce.less';
@import 'dropdowns.less';
@import 'nav-navbar.less';
@import 'checkboxes.less';


body {
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.-text-comm {
  color: var(--gray-40); 
}

.-text-kb {
  color: var(--gray-40); 
}

a.-line {
  text-decoration: underline; 
}

a.block {
  display: block; 
}
a.block:hover, a.block:focus, a.block:active {
  text-decoration: none; 
}

//a.text-muted {
//  color: #525c8d; 
//}

a.content {
  color: @brand-primary; 
}

.icon-link:hover, .icon-link:active, .icon-link:focus {
  text-decoration: none; 
}
.icon-link:hover .icon-link__text, .icon-link:active .icon-link__text, .icon-link:focus .icon-link__text {
  text-decoration: underline; 
}

.btn:hover, .btn:active, .btn:focus, .btn:active:focus {
  outline: none;
}

.btn-primary,
.open > .btn-primary.dropdown-toggle {
  background-color: @brand-primary;
  border-color: transparent;
}

.btn-primary[disabled], .btn-primary[disabled]:focus, .btn-primary[disabled]:active,
.open > .btn-primary.dropdown-toggle:focus,
.open > .btn-primary.dropdown-toggle:hover {
  background-color: var(--gray-30);
  border-color: var(--gray-30);
}

//.btn-primary:hover, .btn-primary:active, .btn-primary:focus, .btn-primary:active:focus {
//  background-color: @brand-highlight;
//  border-color: transparent;
//}

.btn-default:hover, .btn-default:active, .btn-default:focus, .btn-default:active:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: var(--light);
  border: solid 1px var(--gray-60);
  color: var(--gray-60);
}

.btn-default[disabled], .btn-default[disabled]:hover, .btn-default[disabled]:active, .btn-default[disabled]:focus, .btn-default[disabled]:active:focus {
  background-color: var(--light);
  border: solid 1px var(--gray-50);
}

.btn-link {
  color: @brand-primary; 
}
.btn-link:hover, .btn-link:focus, .btn-link:active {
  color: @brand-highlight; 
}

.btn-row {
  margin-bottom: 10px; 
}
.btn-row.-right {
  text-align: right; 
}

.label-primary {
  background-color: @brand-primary; 
}

.panel-primary > .panel-heading {
  background-color: @brand-primary; 
}

.progress-bar {
  background-color: var(--blue-40);
}

.bullet-list {
  padding-left: 0;
  word-wrap: break-word;
  margin-bottom: 20px;
}
.bullet-list.-indent {
  padding-left: 15px; 
}
.bullet-list > li, .bullet-list > a {
  list-style: none; 
}
.bullet-list > li,
.bullet-list > a {
  padding-left: 12px;
  position: relative;
  margin: 5px 0px 0px 8px;
  line-height: 1.8;
}
//.bullet-list > li > a, .bullet-list > a {
//  color: var(--gray-40);
//}
.bullet-list > a:hover,
.bullet-list > li:hover > a,
.bullet-list > li:hover::before,
.bullet-list > li:hover > a::before {
  color: @brand-highlight;
}
.bullet-list > li::before,
.bullet-list > a::before {
  content: "\e258";
  font-family: 'Glyphicons Halflings';
  font-size: 12px;
  font-weight: 400;
  float: left;
  margin-top: 3px;
  margin-left: -20px;
  margin-right: 15px;
  color: var(--gray-40);
}
.bullet-list > li.edit-item {
  padding-left: 0;
  margin-left: 0;
}
.bullet-list > li.edit-item::before {
  content: "";
}
.bullet-list > li:not(:last-child),
.bullet-list > a:not(:last-child) {
  margin-bottom: 5px; 
}
.bullet-list.-sm {
  font-size: 14px; 
}
.bullet-list.-sm > li::before,
.bullet-list.-sm > a::before {
  top: 4px;
  float: none;
  text-decoration: none;
}
.bullet-list.-sm > a:hover::before {
  color: @brand-highlight;
}
.link-list {
  padding-left: 0;
  word-wrap: break-word; 
}
.link-list.-indent {
  padding-left: 15px; 
}
.link-list > li, .link-list > a {
  list-style: none; 
}
.link-list.-bordered > li {
  border-bottom: 1px solid var(--gray-20);
}
.link-list.-bordered > li:last-child {
  border-bottom: none; 
}
.link-list.-hover > li:hover {
  background-color: var(--gray-05); 
}
.link-list.-striped > li:nth-child(odd) {
  background-color: var(--gray-05);
}
.link-list > li > a {
  display: block;
  padding: 10px 20px;
  color: var(--gray-40);
}
.link-list > li > a:hover {
  text-decoration: none; 
}
.aside .link-list > li::before,
.aside .link-list > a::before {
  content: "\e258";
  font-family: 'Glyphicons Halflings';
  font-size: 12px;
  font-weight: 400;
  float: left;
  margin-top: 12px;
  color: var(--gray-40);
}

.padded-list {
  padding-left: 0;
  word-wrap: break-word; 
}
.padded-list.-indent {
  padding-left: 15px; 
}
.padded-list > li, .padded-list > a {
  list-style: none; 
}
.padded-list.-bordered > li {
  border-bottom: 1px solid var(--gray-05); 
}
.padded-list.-bordered > li:last-child {
  border-bottom: none; 
}
.padded-list.-striped > li:nth-child(odd) {
  background-color: var(--gray-05);
}
.padded-list > li {
  padding: 10px 0;
  border-radius: 4px; 
}

.collapse-list {
  list-style-type: none;
  padding: 0; 
}
.collapse-list__item {
  padding: 5px;
  -webkit-transition: background-color 500ms linear;
  transition: background-color 500ms linear; 
}
.collapse-list__item.-highlight {
  background-color: #f7ecb5;
  border-radius: 7px; 
}
.collapse-list__link > .glyphicon-menu-down {
  font-size: 10px;
  position: relative;
  top: -1px;
  padding: 5px; 
}
.collapse-list__link > .label {
  vertical-align: middle; 
}
.collapse-list__link.collapsed > .glyphicon-menu-down {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg); 
}
.collapse-list__title {
  font-size: 16px;
  line-height: normal; 
}
.collapse-list__content {
  padding: 15px 15px 15px 25px; 
}

.edit-item {
  position: relative; 
}
.edit-item__controls {
  float: left;
  margin-right: 5px; 
}

dd {
  margin-bottom: 15px; 
}

.no-content {
  padding: 20px;
  color: var(--gray-50);
}
.no-content.-center {
  text-align: center; 
}

.nm {
  margin: 0; 
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 50;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center; 
}
.overlay > img {
  -webkit-transition: opacity 500ms linear;
  transition: opacity 500ms linear;
  opacity: 0; 
}
.overlay > img.in {
  opacity: 1; 
}
.overlay-wrap {
  min-height: 80px;
  position: relative; 
}

.glyphicon.-sm {
  font-size: 14px; 
}

.glyphicon.-md {
  font-size: 24px; 
}

.glyphicon.-lg {
  font-size: 48px; 
}

.ra-fa-share-alt:before {
  content: "\f1e0"; 
}

.dot {
  color: @brand-primary;
}

.dot::after {
  content: "·";
  display: inline;
  position: relative;
  top: 2px;
  padding: 0 3px; 
}

.badge {
  font-family: sans-serif; 
}

.section {
  margin-bottom: 3rem;
  padding: 20px;
  background-color: var(--light);
  border-radius: 4px;
  border: 1px solid @gray-lighter;
  box-shadow: 0px 5px 5px rgba(0,0,0,0.08);
}
.section__title {
  //font-family: @font-family-sans-serif;
  font-weight: normal;
  font-size: 1.4em;
  padding: 0;
  margin: 0 0 10px; 
}
.section__title.-center { }
.section__title.-strong {
  font-weight: bold; 
}
.section__title.-sm {
  font-size: 1.7em;
  margin-bottom: 7px; 
}
.section .aside {
  border: none;
  padding: 0;
}

select.-sm {
  height: auto;
  width: auto;
  padding: 2px;
  font-size: 14px; 
}

.head-sep {
  position: relative;
  border-top: 1px solid @brand-secondary;
  margin: 22px -20px;
}
.head-sep__icon {
  position: absolute;
  top: -20px;
  left: 50%;
  width: 40px;
  height: 40px;
  margin-left: -20px;
  padding-top: 2px;
  background-color: var(--light);
  border: 5px solid var(--light);
  border-radius: 50%;
  font-size: 26px;
  color: @brand-primary;
  text-align: center;
  line-height: 1;
  display: none;
}

.user-info__content {
  display: inline-block;
  vertical-align: top;
  padding: 0 10px; 
}

.user-info__image {
  display: inline-block; 
}

.user-info__title {
  //font-family: @font-family-sans-serif;
  font-size: 1.4em;
  font-weight: normal;
  margin: 0;
}

.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: @font-family-sans-serif; 
}

.page-title {
  font-family: @font-family-sans-serif;
  font-weight: bold;
  font-size: 1.4em;
  margin: 0 0 20px;
}

.scroll-top {
  display: block;
  position: fixed;
  bottom: 40px;
  right: -60px;
  background-color: #777;
  background-image: @brand-gradient;
  padding: 4px 4px 0 8px;
  border-radius: 4px 0 0 4px;
  //opacity: 0.6;
  color: var(--light);
  font-size: 1.5rem;
  -webkit-transition: right 100ms linear;
  transition: right 100ms linear; 
}
@media (min-width: 992px) {
  .scroll-top {
    padding: 7px 10px 7px 14px; 
} }
.scroll-top.-active {
  right: 0; 
  z-index: 10;
}
.scroll-top:hover, .scroll-top:focus, .scroll-top:active {
  color: var(--light);
  outline: none; 
}
.scroll-top:hover {
  opacity: 1;
  font-size: 2.5rem;
}

.page-error {
  position: fixed;
  bottom: 0;
  left: 0;
  margin: 0;
  width: 100%;
  border-radius: 0; 
}
@media (min-width: 768px) {
  .page-error {
    bottom: 25px;
    left: auto;
    right: 25px;
    width: auto;
    border-radius: 4px; 
} }

.form__title {
  margin: 0 0 15px;
  font-size: 1.4em;
  //font-family: @font-family-sans-serif; 
}



.kb-icon { color: #892C25; 
}

.comm-icon { color: var(--purple-70); 
}

.target-highlight :target {
  -webkit-animation: highlight 1s ease;
  animation: highlight 1s ease; 
}

@-webkit-keyframes highlight {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); 
}
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1); 
}
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); 
} }

@keyframes highlight {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); 
}
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1); 
}
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); 
} }

@media (max-width: 767px) {
  body {
    padding-top: 85px; 
} }




.footer {
  //margin-top: 50px;
  //background-image: @brand-gradient;
  background-color: @brand-primary;
  //margin-top: 4rem;
}
.footer__content {
  color: var(--gray-50);
  padding: 30px 10px;
}
.footer__content > div.container {
  width: 100%;
  //text-align: center;
}
.footer__head {
  text-transform: uppercase;
  font-weight: bold;
  margin: 0;
  line-height: 2.3em;
  color: var(--light);
font-size: 1.75rem
}



@media (max-width: 767px) {
  .footer__group {
    margin-bottom: 25px; 
}
  .footer__group:last-child {
    margin-bottom: 0; 
} }
.footer__link-list {
  list-style-type: none;
  padding: 0;
  margin-bottom: 0; 
  font-size: 1.25rem;
}
.footer__link-list > li {
  margin: .5rem 0; 
}
.footer__link-list > li > a {
  line-height: 1.5em; 
  color: var(--light);
  text-decoration: none;
  &:hover {
color: @brand-secondary  }

}
.footer__version {
  padding: 15px 0 0 0;
  color: var(--gray-50);
  //text-align: center;
  font-size: 10px;
}

.footer > .footer__version {
  display: none;
}

.aside-no-margin__solution {
  margin-bottom: 2rem;
  position: relative;
  
}


@media (min-width: 992px) {
  .aside-no-margin { }

  .aside-no-margin__home {
    padding-right: 0;
    margin-top: -20px;
  }

  .aside-no-margin__account {
    margin-top: 25px;
  }

  .aside-no-margin__solution {
    right: -10px;
    margin-bottom: 55px;
  }

  .aside-no-margin__discussion { }

  .aside-no-margin__document { }

  .aside-no-margin .aside,
  .aside-no-margin__home .aside,
  .aside-no-margin__account .aside,
  .aside-no-margin__results .aside,
  .aside-no-margin__solution .aside,
  .aside-no-margin__discussion .aside,
  .aside-no-margin__document .aside, .aside-no-margin__document .aside-detail {
    border-radius: 0;
    margin-bottom: -1px;
  }

  .aside-no-margin__results section {
    border: 1px solid var(--gray-20);
    border-radius: 0;
    margin: -1px 0;
    box-shadow: none;
  }

  .aside-no-margin__results .aside {
    margin-top: -1px;
  }
  .aside-no-margin__solution {
    position: fixed;
    z-index: 10;
    top:10rem;
  }
  
}

@media (max-width: 991px) {
  .aside-no-margin .aside,
  .aside-no-margin__home .aside,
  .aside-no-margin__account > .aside,
  .aside-no-margin__results > #favDisplay > .aside,
  .aside-no-margin__solution .aside,
  .aside-no-margin__discussion .aside,
  .aside-no-margin__document .aside, .aside-no-margin__document .aside-detail {
    border: 1px solid var(--gray-05);
    -webkit-box-shadow: 0px 5px 5px rgba(0,0,0,0.08);
    box-shadow: 0px 5px 5px rgba(0,0,0,0.08);
  }

  .aside-no-margin__results > section.section,
  .aside-no-margin__results > #favDisplay > .aside {
    margin: 10px;
  }

  .aside-no-margin__home .aside,
  .aside-no-margin__account > .aside {
    margin-left: 4px;
  }
}

.aside, .aside-detail {
  background-color: var(--light);
  border: 1px solid var(--gray-20);
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 20px;
}
.aside.-full { }
.aside.-full .tab-bar.-sm {
  padding-left: 13px; 
}
.aside.-full .aside__wrap {
  padding-left: 20px;
  padding-right: 20px; 
}
.aside__title {
  //font-family: @font-family-sans-serif;
  font-size: 1.4em;
  font-weight: normal;
  margin: 0 0 15px; 
}
.aside__title.-small {
  font-size: 1.5em;
  margin-bottom: 5px; 
}
.aside.-full .aside__title { }
.aside__subtitle {
  font-size: 1em;
  font-weight: bold;
  color: var(--gray-50);
}
.aside__ctrls {
  padding: 5px 0; 
}
.aside__footer {
  margin-right: -20px;
  margin-left: -20px;
  margin-bottom: -20px;
  padding: 10px 20px;
  background-color: @panel-footer-bg;
  border-radius: 0;
  color: @gray-dark; 
}
.aside.-full .aside__footer { }

.aside-detail {
  margin-bottom: 20px;
  padding-left: 20px; 
}
.aside-detail:last-child {
  margin-bottom: 0; 
}
.aside-detail__title {
  //font-family: @font-family-sans-serif;
  font-size: 1.6em;
  font-weight: normal;
  padding-bottom: 5px;
  margin-left: -10px;
  margin-bottom: 10px;
  border-bottom: 1px solid var(--gray-20); 
}

.account {
  position: relative; 
}
.account__image {
  width: 95px;
  float: left; 
}
.account__image .user-image > div > a {
  font-size: 12px;
  font-weight: 600;
}
.account__content {
  margin-left: 105px; 
}
.account__content .bullet-list > li, .account__content .bullet-list > a {
  line-height: 1.2;
}
.account__content .bullet-list > li:before, .account__content .bullet-list > a:before {
  margin-top: 1px;
}
.account__edit-btn-wrap {
  margin: 15px 0; 
}
.account__title {
  margin: 0;
  font-size: 1.4em;
}
.account__subtitle {
  font-size: 1em;
  font-weight: 600;
}
.account__subtitle > a {
  color: var(--gray-40);
  font-weight: 400;
}
.account__subtitle > a:hover, .account__subtitle > a:active, .account__subtitle > a:focus {
  color: @brand-highlight;
}
.account__section {
  float: left;
  width: 100%; 
}
@media (min-width: 768px) {
  .account__section {
    width: 50%; 
} }
.account__section-title {
  font-family: @font-family-sans-serif;
  font-size: 14px;
  font-weight: bold;
  margin: 5px 0; 
}
.account__section-text {
  margin-bottom: 5px; 
}
.account__success-wrap {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  margin-bottom: 0;
  background-color: rgba(223, 240, 216, 0.9);
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.25);
  opacity: 0;
  -webkit-transition: opacity 200ms linear;
  transition: opacity 200ms linear; 
}
.account__success-wrap.-in {
  opacity: 1; 
}

.game-summary {
  background-color: var(--light);
  border-radius: 4px;
  border: 1px solid var(--gray-05);
  padding: 25px 10px 15px 10px;
  position: relative;
  -webkit-box-shadow: 0px 5px 5px rgba(0,0,0,0.08);
  box-shadow: 0px 5px 5px rgba(0,0,0,0.08);
  margin-top: 25px;
  margin-bottom: 20px;
}
.game-summary__icon {
  position: relative;
  width: 50px;
  left: 50%;
  margin-left: -25px;
  padding: 7px 0;
  background-color: var(--light);
  border: 2px solid var(--yellow-30);
  border-radius: 50%;
  text-align: center;
  font-size: 30px;
  line-height: 1;
  color: var(--yellow-30); 
}
.game-summary__title {
  text-align: center;
  //font-family: @font-family-sans-serif;
  margin: 30px 0 5px 0;
  font-weight: bold;
  font-size: 1.4em;
}
.game-summary__detail {
  text-align: center;
  margin-bottom: 30px; 
}
.game-summary__detail > div .dot + span {
  color: var(--gray-40);
}
.game-summary__progress {
  margin: 3px 0 0;
  height: 12px; 
}
.game-summary__progress .progress-bar {
  -webkit-transition: width ease 500ms;
  transition: width ease 500ms; 
}
.game-summary__help-link {
  font-size: 1em;
  font-weight: bold; 
}

.my-posts__filter-row {
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 2px solid var(--gray-light-d); 
}

.my-posts__filter-label {
  margin-right: 5px; 
}

.my-posts__results {
  position: relative; 
}

.ticket-table tr.closed > td {
  color: var(--gray-50); 
}

.ticket-table tr.closed a {
  color: #989cad; 
}

.comm-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.comm-title__image-wrap.-center {
  -ms-flex-item-align: center;
  align-self: center; 
}
.comm-title__image {
  max-width: 50px;
  max-height: 50px;
  margin-right: 10px; 
}
.comm-title__name {
  margin: 0 0 5px;
  font-family: @font-family-sans-serif;
  font-size: 1.4em;
  font-weight: bold;
}
.comm-title__name a {
  color: @brand-primary;
}
.comm-title__name a:hover,
.comm-title__name a:active,
.comm-title__name a:focus {
  color: @brand-highlight;
}
.comm-title__subtitle {
  margin: 0;
  font-family: @font-family-sans-serif;
  font-size: 1.8em;
  font-weight: normal;
  line-height: normal; 
}
@media (min-width: 768px) {
  .comm-title.-lg {
    margin-bottom: 25px; 
}
  .comm-title.-lg .comm-title__image {
    max-width: 100px;
    max-height: 100px;
    margin-right: 20px; 
}
  .comm-title.-lg .comm-title__name {
    font-size: 1.4em;
    margin-bottom: 10px; 
} }

.discussion__question {
  padding-bottom: 10px;
  margin: 0 0 10px;
  font-family: @font-family-sans-serif;
  font-size: 1.4em;
  font-weight: normal;
  line-height: normal; 
}

.discussion__head {
  font-family: @font-family-sans-serif;
  border-bottom: 1px solid var(--gray-20);
  padding-bottom: 7px;
  margin: 15px 0;
  font-size: 1.4em;
}

.post {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; 
}
.post__aside {
  -webkit-box-flex: 60px;
  -ms-flex: 60px 1 0;
  flex: 60px 1 0; 
}
.post__main {
  -webkit-box-flex: 100%;
  -ms-flex: 100% 1 1;
  flex: 100% 1 1;
  min-width: 0;
  margin-left: 10px; 
}
.post__view-stats {
  text-align: center;
  font-size: 14px; 
}
.post__content {
  border-bottom: 1px solid var(--gray-05);
  word-wrap: break-word; 
}
.post__content a {
  color: @brand-primary; 
}
.post__content pre {
  overflow: auto;
  max-height: 600px; 
}
.post__create-info {
  padding: 10px 0;
  font-size: 14px; 
}
.post__actions {
  list-style-type: none;
  margin: 0 0 0 -7px;
  padding: 0;
  font-size: 12px;
  font-weight: 600;
}
.post__actions.-inline {
  display: inline-block; 
}
.post__action-item {
  display: inline-block;
  margin-bottom: 5px; 
}
.post__action-sep {
  display: inline-block; 
}
.post__action-link {
  padding: 2px 7px; 
}
@media (min-width: 768px) {
  .post__aside {
    -ms-flex-preferred-size: 90px;
    flex-basis: 90px; 
}
  .post__create-info {
    float: left; 
}
  .post__actions {
    text-align: right; 
}
  .post__actions-wrap {
    padding: 10px 0; 
}
  .post__action-item {
    margin-bottom: 0; 
} }
.post__tags {
  padding: 10px 0; 
}
.post__tag {
  display: inline;
  padding: 2px 5px;
  background-color: var(--purple-70);
  border-radius: 2px;
  color: var(--light);
  font-size: 11px;
  font-weight: bold;
  line-height: 1;
  white-space: nowrap; 
}
.post__tag:hover {
  color: var(--light);
  text-decoration: none;
  background-color: var(--purple-70);
}
.post__comments {
  padding: 10px 0;
  max-width: 800px; 
}
.post__comment {
  font-size: 14px;
  padding: 5px 20px;
  border-bottom: 1px solid var(--gray-05); 
}
.post__comment-content {
  line-height: 1.5;
  color: #444;
  word-wrap: break-word; 
}
.post__comment-content a {
  color: @brand-primary; 
}
.post__comment-content > p {
  margin-bottom: 0; 
}
.post__comment-footer {
  padding: 3px; 
}
.post__add-comment {
  padding: 6px 0; 
}
.post__add-comment-link {
  font-size: 12px;
  font-weight: 600;
}
.post__comment-form {
  padding: 0 20px; 
}
.post__wysiwyg-wrap {
  padding: 10px 0; 
}

.flag-form {
  background-color: var(--gray-05);
  padding: 10px; 
}
.flag-form__title {
  margin: 0 0 5px;
  font-size: 1.2em; 
}
.flag-form__label {
  font-weight: normal; 
}
.flag-form__input {
  border: 1px solid var(--gray-20);
  border-radius: 4px;
  margin-bottom: 5px;
  font-size: 14px;
  padding: 5px;
  width: 250px; 
}

.vote-ctrls {
  text-align: center;
  font-size: 2em;
  margin-top: -10px; 
}
.vote-ctrls__count {
  position: relative;
  top: -4px;
  line-height: 1;
  color: var(--gray-50); 
}
.vote-ctrls__arrow {
  color: var(--gray-50); 
}
.vote-ctrls__arrow.-disabled {
  color: var(--pink-sand); 
}

.share-popup {
  margin-bottom: -5px;
  text-align: center; 
}

.share-button {
  width: 70px;
  font-size: 18px;
  margin-bottom: 5px; 
}

.file-list {
  list-style-type: none;
  margin: 0;
  padding: 5px 0; 
}
.file-list__item {
  display: inline-block; 
}
.file-list__link {
  display: block;
  padding: 5px 10px;
  border: 1px solid var(--gray-05);
  border-radius: 4px;
  font-size: 14px; 
}
.file-list__link:hover {
  text-decoration: none;
  background-color: var(--gray-05);
  border-color: var(--gray-20); 
}
.file-list__icon {
  font-size: 1.7em; 
}
.file-list__name {
  display: inline-block;
  padding-left: 3px;
  max-width: 200px;
  position: relative;
  top: 1px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap; 
}

.answers > .post {
  border-bottom: 1px solid var(--gray-05);
  padding: 15px 0; 
}
.answers > .post.-accepted, .answers > .post.-accepted:first-child, .answers > .post.-accepted:last-child {
  border-radius: 5px;
  border: var(--green-10) 2px solid;
  padding: 15px 10px;
  box-shadow: 0 0 5px var(--green-rgba);
  margin-bottom: 35px; 
}
.answers > .post:first-child {
  padding-top: 0; 
}
.answers > .post:last-child {
  border: none; 
}

.attach-table {
  width: 100%;
  max-width: 550px;
  margin-bottom: 3px;
  border: 1px solid var(--gray-light-d);
  border-collapse: separate;
  border-radius: 4px; 
}
.attach-table tbody > tr > td {
  border: none; 
}
.attach-table__name {
  word-wrap: break-word; 
}
.attach-table__name.-new {
  color: var(--green-70); 
}
.attach-table__size {
  width: 100px;
  text-align: right; 
}
.attach-table__actions {
  width: 25px;
  text-align: right; 
}
.attach-table__remove-action {
  color: @brand-danger;
  font-size: 16px;
  line-height: 1; 
}
.attach-table__remove-action:hover, .attach-table__remove-action:active, .attach-table__remove-action:focus {
  color: var(--chile); 
}
.attach-table__footer {
  margin-bottom: 10px;
  font-style: italic;
  color: var(--gray-50);
  font-size: 14px; 
}

.mce-content-body.editor {
  padding: 0;
  margin: 8px;
  font-size: 14px;
  background-color: var(--light);
}
.mce-content-body.editor a {
  color: @brand-primary; 
}

.tag-editor {
  border: 1px solid var(--gray-20);
  border-radius: 2px;
  padding: 6px 12px; 
}
.tag-editor:focus-within {
  border: 2px solid @brand-highlight;
}
.tag-editor > li {
  margin: 3px;
}
.tag-editor .ui-sortable-handle {
  margin: 0; 
}
.tag-editor .placeholder {
  padding: 0;
  color: var(--gray-30) }
.tag-editor .tag-editor-spacer {
  width: 0; 
}
.tag-editor .tag-editor-tag,
.tag-editor .tag-editor-delete,
.tag-editor .tag-editor-delete i::before {
  color: var(--light);
  background-color: var(--purple-70); 
}
.tag-editor .tag-editor-delete:hover i::before {
  color: var(--light);
}

.subscrpt {
  margin-bottom: 15px;
  font-size: 16px;
  clear: both; 
}
.subscrpt__btn {
  line-height: 1;
  min-width: 70px; 
}

.ui-front {
  z-index: 1050; 
}

.ui-menu {
  padding: 5px 0;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.176); 
}

.ui-menu .ui-menu-item {
  padding: 3px 10px; 
}

.ui-widget-content {
  border-color: var(--gray-light-c);
  border-radius: 4px; 
}

.ui-widget-content .ui-state-hover,
.ui-state-focus,
.ui-widget-content .ui-state-focus {
  background: #6F5499;
  color: var(--light); 
}



#favoriteToolBar .btn{
  padding: 6px 4px;
}




.contact {
  margin-top: 20px; 
}
@media (min-width: 992px) {
  .contact {
    margin-top: 0; 
} }
.contact__link {
  display: block;
  position: relative;
  margin: 10px 0;
  color: inherit;
  font-size: 1.2em; 
}
.contact__link:hover, .contact__link:active, .contact__link:focus {
  text-decoration: none;
  color: var(--blurple); 
}
.contact__icon {
  position: absolute;
  top: 2px;
  font-size: 1.3em; 
}
.contact__icon.-blue {
  color: @brand-primary;
}
.contact__icon.-green {
  color: var(--green-70);
}
.contact__icon.-purple {
  color: var(--purple-70);
}
.contact__icon.-red {
  color: var(--red-50);
}
.contact__label {
  display: inline-block;
  margin-left: 35px; 
}

.profile-section {
  margin-bottom: 25px;
  padding: 15px;
  border-radius: 4px;
  border: 1px solid var(--gray-05);
  background-color: var(--light);
  box-shadow: 0px 5px 5px rgba(0,0,0,0.08);
}
.profile-section__image {
  float: left; 
}
.profile-section__content {
  margin-left: 90px; 
}
.profile-section__links {
  font-size: 1.25rem;
  font-weight: 600;
  //margin-top: 3px;
}

.no-results {
  padding: 20px; 
}

.result-sort.-center {
  text-align: center; 
}

.result-sort.-right {
  float: right;
  width: auto;
}
@media (max-width: 767px) {
  .result-sort.-right {
    float: none; 
} }

.result-sort__label {
  font-size: 14px;
  font-weight: normal;
  padding-right: 5px; 
}

.result-sort__control {
  padding: 2px;
  height: auto;
  font-size: 14px; 
}

.cat-matches {
  font-size: 1em;
}
.cat-matches__title {
  font-size: 1em;
  margin: 0 0 5px;
  line-height: normal;
  font-weight: bold;
  color: var(--gray); 
}
.cat-matches__list {
  margin-left: 12px;
  margin-bottom: 0; 
}

@media (min-width: 768px) {
  .result-summary-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap; 
}
  .result-summary {
    width: 50%;
    min-height: 275px;
    padding: 0 15px 25px; 
} }

@media (max-width: 767px) {
  .result-summary + .result-summary::before {
    display: block;
    width: 75%;
    height: 1px;
    margin: 20px auto;
    border-bottom: 1px solid var(--gray-05);
    content: ""; 
} }

.result-summary__head {
  margin: 18px 0 0;
}

.result-summary__head-link {
  color: inherit;
  font-family: @font-family-sans-serif;
  font-weight: normal;
  font-size: 20px;
}
.result-summary__head-link:hover, .result-summary__head-link:active, .result-summary__head-link:focus {
  text-decoration: none;
  color: @brand-primary; 
}
.result-summary__head-link.-kb:hover, .result-summary__head-link.-kb:active, .result-summary__head-link.-kb:focus {
  color: var(--red-50); 
}
.result-summary__head-link.-comm:hover, .result-summary__head-link.-comm:active, .result-summary__head-link.-comm:focus {
  color: var(--purple-70); 
}

.result-summary__head-link.-kb > .result-summary__head-icon {
  color: var(--red-50);
}

.result-summary__head-link.-comm > .result-summary__head-icon {
  color: var(--purple-70);
}

.result-summary__head-icon {
  margin-right: 5px;
  width: 33px;
  color: @brand-primary;
  vertical-align: bottom; 
}

.result-summary__stats {
  margin: 3px 0 10px 40px;
  font-size: 1em;
  line-height: 1.6;
}

.result-summary__list {
  padding: 0;
  list-style-type: none;
  margin-bottom: 20px; 
}

.result-summary__item {
  margin-bottom: 10px; 
}

.result-summary .view-link {
  padding: 5px 0; 
}

.result-summary .view-link__title {
  font-size: 1.2em;
}

.result-summary .view-link__icon {
  width: 27px;
  text-align: center;
  font-size: 20px; 
}

.result-summary .view-link__content {
  margin-left: 50px;
}

.solution-list {
  list-style: none;
  padding: 0; 
}

.view-link {
  padding: 10px 0;
  line-height: 1.5;
}
.view-link:first-child {
  padding-top: 0 !important; 
}
.view-link__title {
  font-size: 1.2em; 
  text-decoration: none;
  margin-bottom: .5rem;
  display: inline-block;
  &:hover {
      color: @brand-highlight;
      text-decoration: none;
      &:after{
        content: " ";
        display: block;
        //max-width: 50%;
        height: 2px;
        margin-top: -2px;
        background-image: @brand-gradient-smooth;
      }
    
    
  }
}
@media (min-width: 768px) {
  .view-link__title {
    font-size: 1.2em; 
} }
.view-link__summary {
  margin-bottom: .5rem;
}

.view-link__hit-summary {
  margin-bottom: .5rem;
  padding-left: .5rem;
  border-left: 2px solid @brand-secondary;
}

.view-link__avatar {
  float: left;
  width: 85px;
  margin-top: 5px;
  text-align: center; 
}
.view-link__icon {
  float: left;
  width: 1.5rem;
  color: @brand-secondary;
  font-size: 1.25rem;
  padding-left: .5rem;
  padding-top: 3px;
  
  
  
}
//.view-link__icon > i {
//  background: @brand-gradient-extended;
//  -webkit-background-clip: text;
//  -webkit-text-fill-color: transparent;
//}


.view-link__icon.-success {
  color: @alert-success-text; 
}
.view-link__icon.-danger {
  color: @alert-danger-text; 
}
.view-link__icon.-muted {
  color: @text-muted; 
}
.view-link__content {
  margin-left: 2.5rem;
  word-wrap: break-word;
  //color: var(--gray-50);
}
.view-link__sub-line {
  //padding: .25rem 0;
  color: var(--gray-50);
  font-size: 1rem;
  overflow-wrap: break-word;
  word-wrap: break-word; 
}



.result-col .view-link__subhead {
  padding-bottom: 15px;
}
.view-link.-sm {
  padding: 7px 0; 
}
.view-link.-sm .view-link__icon {
  width: 20px;
  font-size: 20px; 
}
.view-link.-sm .view-link__content {
  margin-left: 60px; 
}
.view-link.-sm .view-link__title {
  font-size: 1.2em; 
}

.filter {
  padding: 20px;
  font-size: 1em;
}
.filter:first-child {
  padding-top: 15px; 
}
.filter__title {
  font-size: 1.4em;
}
.filter .-active {
  font-weight: bold;
  color: @gray-dark;
}
.filter__list {
  margin: 0;
  padding: 0;
  list-style-type: none; 
}
.filter .icon-link{
  text-decoration: none;
}

.filter__item {
  margin-bottom: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.8;
}
.filter__item.-in1 {
  margin-left: 15px; 
}
.filter__item.-in2 {
  margin-left: 20px; 
}
.filter__link.-back .glyphicon {
  font-size: 10px;
  vertical-align: baseline; 
}
.filter__list.-tags .filter__item {
  display: inline-block; 
}
.filter__list.-tags .filter__item.-active .filter__link {
  background-color: var(--purple-70);
  color: var(--light); 
}
.filter__list.-tags .filter__item.-active .filter__link:hover {
  background-color: var(--purple-70);
}
.filter__list.-tags .filter__link {
  display: block;
  margin: 0 2px;
  padding: 2px 5px;
  border-radius: 2px;
  background-color: var(--gray-lighter);
  color: var(--gray-50);
  font-size: 11px;
  font-weight: bold;
  line-height: normal; 
}
.filter__list.-tags .filter__link:hover {
  background-color: var(--pink-sand); 
}
.filter__list.-tags .filter__link:hover, .filter__list.-tags .filter__link:focus, .filter__list.-tags .filter__link:active {
  outline: none;
  text-decoration: none; 
}

.filter__list .filter__link {
  color: @gray-base;
  text-decoration: none;
}
.filter__list .filter__link:hover {
  color: @brand-primary;
  &:after{
    display: block;
    content: " ";
    height: 2px;
    margin-top: -2px;
    background-image: @brand-gradient-smooth;
  }
}

.pagination > li > a,
.pagination > li > a:hover,
.pagination > li > a:focus,
.pagination > li > a:active {
  color: @brand-primary; 
}

.pagination > .active > a,
.pagination > .active > a:hover,
.pagination > .active > a:focus,
.pagination > .active > a:active,
.pagination > .active > span,
.pagination > .active > span:hover,
.pagination > .active > span:focus,
.pagination > .active > span:active {
  background-color: @brand-primary;
  border-color: @brand-primary; 
}

.result-col__head {
  padding: 10px 0; 
}

.result-col__head-right {
  display: flex;
  float: right;
}

@media (max-width: 767px) {
  .result-col__head-right {
    flex-direction: column;
    float: none;
    align-items: flex-start;
  }

  .result-col__head-right .result-sort {
    margin-top: 10px;
    padding: 0;
  }
}

.result-col__counts {
  display: block;
  margin-bottom: 5px;
  color: var(--gray-70);
  font-size: 1.4em;
  margin-top: 15px;
}

@media (min-width: 768px) {
  .result-col {
    border-left: 1px solid var(--gray-20); 
}
  .result-col__counts {
    display: inline-block;
    margin-bottom: 0; 
} }

.button-group__item {
  border: 1px solid transparent;
  border-radius: 4px;
  display: inline-block;
  padding: 0px 12px;
  margin: 0 10px 5px 0;
  font-size: 1em;
  line-height: 38px;
  position: relative;
  word-wrap: break-word;
  color: @gray-dark;
  background-color: var(--light);
  border-color: var(--gray-20);
}
.button-group__item:focus, .button-group__item.focus {
  color: @brand-highlight;
  background-color: var(--blue-10);
}
.button-group__item:hover {
  color: @gray-dark;
  background-color: var(--gray-05);
}
.button-group__item:active, .button-group__item.active {
  color: @brand-highlight;
  background-color: var(--blue-10);
}
.button-group__item:hover, .button-group__item:focus {
  outline: none;
  text-decoration: none;
  z-index: 2; 
}
.button-group__item:active, .button-group__item.active {
  -webkit-box-shadow: none;
  box-shadow: none;
}
@media (min-width: 992px) {
  .button-group__item {
    display: block;
    font-size: 1em;
    margin: 0;
    border-radius: 0; 
}
  .button-group__item + .button-group__item {
    margin-right: 0;
    margin-top: -1px; 
}
  .button-group__item:first-child {
    border-radius: 4px 4px 0 0; 
}
  .button-group__item:last-child {
    border-radius: 0 0 4px 4px; 
} }

.button-group__item-count {
  float: right;
  color: var(--gray-50);
  margin-left: 15px; 
}

.filter-toggle {
  display: none; 
}

@media (min-width: 768px) {
  .filter-col {
    position: relative;
    left: 1px;
    border: 1px solid var(--gray-20);
    border-top: none;
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .filter-col {
    width: 250px;
    position: absolute;
    -webkit-transform: translate(-270px, 0);
    transform: translate(-270px, 0);
    background-color: var(--light);
    z-index: 55;
    -webkit-transition: -webkit-transform 300ms ease;
    transition: -webkit-transform 300ms ease;
    transition: transform 300ms ease;
    transition: transform 300ms ease, -webkit-transform 300ms ease; 
}
  .filter-col.open {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    box-shadow: 5px 2px 5px -2px rgba(0, 0, 0, 0.3);
    border-radius: 0 0 5px 0; 
}
  .filter-col .filter-toggle {
    display: block;
    font-size: 20px;
    color: var(--gray);
    line-height: 1;
    position: absolute;
    right: -30px;
    top: 10px;
    padding: 7px 5px;
    background-color: var(--light);
    box-shadow: 5px 2px 5px -2px rgba(0, 0, 0, 0.3);
    border-radius: 0 5px 5px 0;
    cursor: pointer; 
}
  .result-col {
    padding-left: 35px;
  }
  /* SOCIAL-SA KBResults*/
  .tab-content.col-md-9 .filter-col {
    margin-left: -17px;
  }
  .tab-content.col-md-9 .filter-col.open {
    margin-left: -16px;
  }
  /* SOCIAL-SS KBResults*/
  .tab-content.col-xs-12 .filter-col {
    margin-left: -42px;
  }
  .tab-content.col-xs-12 .filter-col.open {
    margin-left: -31px;
  }
}

.solution-head {
  margin-bottom: 15px; 
}
.solution-head__title {
  font-family: "Merriweather", "Open Sans", "Open Sans Condensed", "Arial Narrow", sans-serif;
  font-weight: bold;
  font-size: 3rem;
  margin: 0 0 5px;
  word-wrap: break-word; 
}
.solution-head__info {
  color: var(--gray-50);
  font-size: 1em;
  font-style: italic; 
}

.solution-content {
  margin-bottom: 2rem;
  //line-height: 1.6em;
  word-wrap: break-word; 
}

.solution-content__field-head {
  clear: both;
  margin-top: 30px;
  margin-bottom: 10px;
  padding-bottom: 5px;
  //font-family: @font-family-sans-serif;
  font-weight: normal;
  font-size: 1.4em;
  border-bottom: 1px solid var(--gray-20);
}
.solution-content__footer {
  margin-top: 30px; 
}
.solution-content__footer-item {
  margin-bottom: 10px; 
}

.diag-steps a {
  color: @brand-primary; 
}

.diag-steps__question {
  font-size: 1.1em;
  font-style: italic; 
}

.diag-responses {
  list-style-type: none;
  margin: 15px 0 15px 15px;
  padding: 0; 
}
.diag-responses__item {
  margin-bottom: 5px;
  font-size: 1.1em; 
}

.lookahead {
  font-size: 1.1em; 
}
.lookahead__question {
  font-style: italic;
  margin: 5px 0; 
}
.lookahead__list {
  list-style: none;
  margin: 0 0 0 15px;
  padding: 0; 
}
.lookahead__item {
  margin-bottom: 5px; 
}
.lookahead__wrap {
  margin-left: 20px; 
}

.feedback__vote {
  margin: 30px 0;
  text-align: center;
  padding: 0; 
}

.feedback__link {
  //.button-variant(@btn-primary-color; transparent; @btn-primary-border);

  .btn();
  .btn-lg();
  .button-variant(@btn-default-color; @btn-default-bg; @btn-default-border);

  //display: inline-block;
  //padding: 10px;
  //background-color: var(--blurple-light);
  //border-radius: 4px;
  //font-size: 1.4em;
  //border: 1px solid var(--gray);
  //text-decoration: none;
  //line-height: @line-height-single;
}
.feedback__link:first-of-type {
  margin-right: 5px;
  //margin-bottom: 5px; 
}
.feedback__link:hover, .feedback__link:active, .feedback__link:focus {
  text-decoration: none;
  outline: none; 
}
.feedback__link.-up {
  .button-variant(@state-success-text; @state-success-bg; @state-success-border);
}
//.feedback__link.-up:focus, .feedback__link.-up.focus {
//  color: @state-success-text;
//  background-color: var(--state-success-bg);
//  border-color: var(--state-success-border-shade);
//}
//.feedback__link.-up:hover {
//  color: @state-success-text;
//  background-color: var(--state-success-bg);
//  border-color: var(--state-success-border); 
//}
//.feedback__link.-up:active, .feedback__link.-up.active {
//  color: @state-success-text;
//  background-color: var(--state-success-bg);
//  border-color: var(--state-success-border); 
//}
//.feedback__link.-up.-voted, .feedback__link.-up.-voted:hover, .feedback__link.-up.-voted:focus, .feedback__link.-up.-voted:active {
//  background-color: var(--state-success-bg);
//  border-color: var(--state-success-border);
//  cursor: default; 
//}
.feedback__link.-down {
  .button-variant(@state-danger-text; @state-danger-bg; @state-danger-border);
  //color: var(--state-danger-text);
  //background-color: var(--state-danger-bg);
  //border-color: var(--state-danger-border-light);
}
//.feedback__link.-down:focus, .feedback__link.-down.focus {
//  color: var(--state-danger-text);
//  background-color: var(--state-danger-bg-shade);
//  border-color: var(--state-danger-border-shade); 
//}
//.feedback__link.-down:hover {
//  color: var(--state-danger-text);
//  background-color: var(--state-danger-bg-shade);
//  border-color: var(--state-danger-border); 
//}
//.feedback__link.-down:active, .feedback__link.-down.active {
//  color: var(--state-danger-text);
//  background-color: var(--state-danger-bg-shade);
//  border-color: var(--state-danger-border); 
//}
//.feedback__link.-down.-voted, .feedback__link.-down.-voted:hover, .feedback__link.-down.-voted:focus, .feedback__link.-down.-voted:active {
//  background-color: var(--state-danger-bg-shade);
//  border-color: var(--state-danger-border);
//  cursor: default; 
//}
.feedback__link.-disabled {
  .button-variant(@gray-light; @gray-lighter; @gray-light);
  cursor: default; 
}

.feedback__stat {
  display: inline-block;
  margin: 0 10px; 
}



.comments { margin-top: 20px; 
}
.comments__head {
  padding-top: 1.5rem;
  border-bottom: 1px solid var(--gray-20);
  color: @brand-primary
}
.comments__title {
  font-size: 1.4em;
  font-weight: 400;
  //font-family: @font-family-sans-serif;
  margin-bottom: 5px;
.glyphicon{
  top: .25rem;
}
}

.comments__no-login-msg {
  padding: 20px;
  background-color: @panel-footer-bg;
  border: 1px solid @panel-inner-border;
  border-radius: 10px;
  text-align: center; 
}
//.comments__no-login-msg a, .comments__no-login-msg a:hover, .comments__no-login-msg a:active, .comments__no-login-msg a:focus {
//  color: @brand-warning; 
//}
.comments__actions {
  padding-bottom: 5px;
  text-align: right; 
}

.comment-form__input-wrap {
  margin-bottom: 15px; 
}

.comment-form__user-image {
  display: none; 
}

@media (min-width: 768px) {
  .comment-form__user-image {
    display: block; 
}
  .comment-form__input-wrap {
    margin-left: 65px; 
}
  .comment-form__input-wrap.-sm {
    margin-left: 55px; 
} }

.comment-form__input {
  display: block;
  padding: 9px 12px;
  border: 1px solid var(--gray-light-c);
  border-radius: 4px;
  font-size: 18px;
  line-height: normal;
  width: 100%;
  height: 45px;
  -webkit-transition: height 200ms linear;
  transition: height 200ms linear; 
}
.comment-form__input.-open {
  height: 150px;
  border-radius: 4px 4px 0 0; 
}
.comment-form__input.-open.-sm {
  height: 120px; 
}
.comment-form__input.-sm {
  font-size: 14px;
  padding: 3px 10px;
  height: 30px; 
}

.comment-form__input-footer {
  background-color: var(--gray-05);
  border-radius: 0 0 4px 4px;
  padding: 5px 10px;
  text-align: right;
  border-color: var(--gray-light-c);
  border-width: 0 1px 1px;
  border-style: solid; 
}

.comment-form__input-footer > span {
  padding-left: 20px;
}

.comment {
  border-bottom: 1px solid var(--pink-sand);
  padding: 15px 5px 5px 5px; 
}
.comment:last-child {
  border-bottom: none; 
}
.comment__wrap {
  margin-left: 55px; 
}
.comment__username {
  font-weight: bold; 
}
.comment__time {
  color: var(--gray-50); 
}
.comment__content {
  padding-top: 10px;
  word-wrap: break-word; 
}
.comment__actions {
  padding-bottom: 10px; 
}
.comment__actions > a {
  font-size: 12px;
  font-weight: 600;
}
.comment__actions:hover > a { }
.comment .comment {
  margin-left: 20px;
  border-top: 1px solid var(--pink-sand);
  border-bottom: none; 
}
.comment__reply-wrap {
  border-radius: 4px;
  margin-left: 10px;
  margin-bottom: 5px; 
}

.view-error {
  margin: 0 auto 20px;
  max-width: 600px;
  padding: 10px;
  min-height: 100px; 
}
.view-error.-denied {
  background: url("@{ra-image-path}solution-denied.png") no-repeat 10px 10px;
  padding-left: 100px; 
}
.view-error.-not-found {
  background: url("@{ra-image-path}solution-not-found.png") no-repeat 10px 10px;
  padding-left: 100px; 
}
.view-error__title {
  margin: 7px 0;
  //font-family: @font-family-sans-serif;
  font-size: 3em; 
}

iframe#titlesFrame {
  width: 100%;
  border: none;
  overflow: hidden; 
}

body#titlePreferencesPage {
  padding-top: 0; 
}

#titleform h4 {
  font-size: 1em;
  font-weight: bold; 
}

#titleform label {
  font-weight: normal; 
}

#titleform label > input[disabled] + span {
  color: var(--gray-40);
}

#titleform label > input:not([disabled]) + span {
  color: @gray-dark;
}

#titleform label > input:checked + span {
  color: @brand-primary;
}

.algolia-autocomplete .aa-hint {
  color: var(--gray-50); 
}

.algolia-autocomplete .aa-dropdown-menu {
  margin-top: 2px;
  padding-bottom: 5px;
  background-color: var(--light);
  border: 1px solid var(--gray-light-c);
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2); 
}
.algolia-autocomplete .aa-dropdown-menu .aa-suggestion {
  padding: 3px 10px 3px 27px;
  cursor: pointer; 
}
.algolia-autocomplete .aa-dropdown-menu .aa-suggestion__ctx {
  color: var(--purple-70); 
}
.algolia-autocomplete .aa-dropdown-menu .aa-suggestion.aa-cursor {
  background-color: var(--gray-05); 
}
.algolia-autocomplete .aa-dropdown-menu .aa-suggestion em {
  font-weight: bold;
  font-style: normal; 
}
.algolia-autocomplete .aa-dropdown-menu .aa-suggestion p {
  margin: 0; 
}
.algolia-autocomplete .aa-dropdown-menu .aa-dataset-header {
  margin-top: 1rem;
  padding: 0 1rem;
  font-size: 1rem;
  font-weight: bold;
  color: @brand-primary; 
}
.algolia-autocomplete .aa-dropdown-menu .aa-dataset-header.-kb > .glyphicon {
  color: @brand-primary; 
}
.algolia-autocomplete .aa-dropdown-menu .aa-dataset-header.-comm > .glyphicon {
  color: @brand-secondary; 
}

.basic-pager {
  text-align: center;
  padding: 5px 0; 
}
.basic-pager__label {
  padding: 0 7px;
  font-size: 12px;
  font-weight: 600;
  color: @brand-primary;
}



.tab-bar {
  padding: 0;
  margin: 0;
  margin-left: -1px;
  list-style-type: none;
  border-bottom: 1px solid var(--gray-20);
  font-size: 14px; 
}
.tab-bar__item {
  float: left; 
}
.tab-bar__item.active > .tab-bar__link {
  border-color: @brand-primary;
  color: @brand-primary;
}
.tab-bar__item.active > .tab-bar__link.-kb {
  border-color: @brand-highlight;
}
.tab-bar__item.active > .tab-bar__link.-comm {
  border-color: @brand-highlight;
}
.tab-bar__link {
  display: block;
  padding: 7px;
  border-bottom: 5px solid transparent;
  color: var(--gray-50);
  font-weight: bold; 
}
.tab-bar__link:hover, .tab-bar__link:focus, .tab-bar__link:active {
  text-decoration: none;
  outline: none;

}
@media (min-width: 768px) {
  .tab-bar {
    font-size: inherit; 
}
  .tab-bar__link {
    padding: 5px 15px; 
} }
.tab-bar.-sm .tab-bar__link {
  padding: 5px 7px;
}

.user-image {
  display: inline-block;
  text-align: center;
  width: 80px; 
}
.user-image > a {
  display: block; 
}
.user-image > a:hover, .user-image > a:focus, .user-image > a:active {
  outline: none;
  text-decoration: none;
  color: inherit; 
}
.user-image > a:hover .user-image__avatar {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.user-image__avatar {
  display: block;
  max-width: 65px;
  //margin: 4px auto;
  //border-radius: 25%;
  //box-shadow: 0 0 3px rgba(0, 0, 0, 0.5); 
}
.user-image__caption {
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--gray-50); 
}
.user-image.-xs {
  width: auto;
  vertical-align: bottom; 
}
.user-image.-xs .user-image__avatar {
  max-width: 18px;
  max-height: 18px;
  border-radius: 4px; 
}
.user-image.-xs .user-image__label {
  font-size: 1em; 
}
.user-image.-sm {
  width: 45px; 
}
.user-image.-sm .user-image__avatar {
  max-width: 30px; 
}
.user-image.-md {
  width: 60px; 
}
.user-image.-md .user-image__avatar {
  max-width: 45px; 
}
.user-image.-lg {
  width: 95px; 
}
.user-image.-lg .user-image__avatar {
  max-width: 65px;
}

.cat-browser {
  position: relative; 
}
.cat-browser__list {

  display: flex;

  flex-wrap: wrap;

  justify-content: center; 
}
.cat-browser__title-list {
  overflow: auto;
  max-height: 300px; 
& a{
  text-decoration: none;
}
  
& a:hover{
  text-decoration: underline;
}
}


.cat-browser__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 10rem;
  min-height: 75px;
  margin: .5rem;
  padding: 1rem;
  border: 1px solid @gray-lighter;
  border-radius: 4px;
  word-wrap: break-word;
  text-decoration: none;
  //z-index: 1;
  //transition: box-shadow 0.3s ease-in-out; /* Smooth transition */
  //position: relative; /* Needed for the pseudo-element */
  //background:var(--light);
}
//
//.cat-browser__item::before {
//  content: '';
//  position: absolute;
//  top: -.25rem;
//  left: -.25rem;
//  right: -.25rem;
//  bottom: -.25rem;
//  width:calc(100% + .5rem);
//  height: calc(100% + .5rem);
//  z-index: -1; /* Place behind the item content */
//  border-radius: 8px; /* Match the border-radius of the item */
//  background: @brand-gradient;
//  opacity: 0; /* Hidden by default */
//  transition: opacity 0.3s ease-in-out; 
//  box-shadow: inset 0 0 1px 1px rgba(255, 255, 255, 0.8),
//  inset 0 0 2px 2px rgba(255, 255, 255, 0.6),
//  inset 0 0 3px 3px rgba(255, 255, 255, 0.4),
//  inset 0 0 4px 4px rgba(255, 255, 255, 0.2); 
//}
//.cat-browser__item::after {
//  content: '';
//  position: absolute;
//  top: 0;
//  left: 0;
//  right: 0;
//  bottom: 0;
//  z-index: 1; /* Place behind the item content */
//  border-radius: 4px; /* Match the border-radius of the item */
//  background: var(--light);
//  opacity: 1; /* Hidden by default */
//}
//
//.cat-browser__item:hover::before,
//.cat-browser__item:focus::before,
//.cat-browser__item:active::before {
//  //background: @brand-gradient;
//  opacity: 1; /* Fade in the gradient */
//}
//
//.cat-browser__item:hover,
//.cat-browser__item:focus,
//.cat-browser__item:active {
//  text-decoration: underline;
//  //box-shadow: 0 0 5px rgba(0, 0, 0, 0.5); /* Optional: additional shadow for better visibility */
//}



.actions-list__item,
.cat-browser__item,
.shortcut-panel__item {
  z-index: 1;
  transition: box-shadow 0.3s ease-in-out;
  position: relative;
  background: var(--light);
}

.cat-browser__item::before,
.shortcut-panel__item::before,
.actions-list__item::before {
  content: '';
  position: absolute;
  top: -.25rem;
  left: -.25rem;
  right: -.25rem;
  bottom: -.25rem;
  width: calc(100% + .5rem);
  height: calc(100% + .5rem);
  z-index: -1;
  border-radius: 8px;
  background: @brand-gradient;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  box-shadow: inset 0 0 1px 1px rgba(255, 255, 255, 0.8),
  inset 0 0 2px 2px rgba(255, 255, 255, 0.6),
  inset 0 0 3px 3px rgba(255, 255, 255, 0.4),
  inset 0 0 4px 4px rgba(255, 255, 255, 0.2);
}

.cat-browser__item::after,
.shortcut-panel__item::after,
.actions-list__item::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  border-radius: 4px;
  background: var(--light);
  opacity: 1;
}
.actions-list__item:hover::before,
.actions-list__item:focus::before,
.actions-list__item:active::before,
.cat-browser__item:hover::before,
.cat-browser__item:focus::before,
.cat-browser__item:active::before,
.shortcut-panel__item:hover::before,
.shortcut-panel__item:focus::before,
.shortcut-panel__item:active::before {
  opacity: 1;
}

.cat-browser__item:hover,
.cat-browser__item:focus,
.cat-browser__item:active,
.shortcut-panel__item:hover,
.shortcut-panel__item:focus,
.shortcut-panel__item:active {
  .card-subtext {
    text-decoration: underline;
  }
  }



.cat-browser__item.-active {
  z-index: 51;
  background-color: var(--gray-05);
  border-color: var(--gray-50);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175); 
}
.cat-browser__item.-sm {
  min-height: 50px;
  width: 100px;
  margin: 4px; 
}
.cat-browser__item.-sm > .cat-browser__name {
  font-size: 14px;
  line-height: normal;
}
.cat-browser__item.-sm > .cat-browser__count {
  margin-top: 5px; 
}
.cat-browser__name {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  //font-size: 14px;
  line-height: 1.1;
  //color: @brand-primary;
  position: relative;
  z-index: 2;
 }

.cat-browser__count {
  margin-top: 10px;
  font-size: 14px;
  //color: var(--gray-50); 
  position: relative;
  z-index: 2;
}

.cat-browser__item, .cat-browser__item.-active{
  &:hover,
  &:active,
  &:visited,
  &:focus {

      text-decoration: none; 

  }

}


.cat-browser__menu {
  position: absolute;
  width: 100%;
  padding: 15px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  background-color: var(--light);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  z-index: 51; 
}
.cat-browser__menu-title {
  //font-family: @font-family-sans-serif;
  font-size: 1.25rem;
  margin: 0 0 7px;
  word-wrap: break-word;
}
.cat-browser__all-wrap {
  margin-bottom: 15px;
  font-size: 1em;
}
@media (min-width: 768px) {
//  .cat-browser__item {
//    width: 150px;
//    margin: 8px; 
//}
  .cat-browser__name {
    font-size: 18px; 
} }

//.star-rating {
//  background-image: url("@{ra-image-path}stars-blue.png");
//  background-color: transparent;
//  background-repeat: no-repeat;
//  display: inline-block;
//  width: 53px;
//  height: 12px; 
//}
//.star-rating.-star5 {
//  background-position: 0px 0px; 
//}
//.star-rating.-star4 {
//  background-position: -11px 0px; 
//}
//.star-rating.-star3 {
//  background-position: -21px 0px; 
//}
//.star-rating.-star2 {
//  background-position: -32px 0px; 
//}
//.star-rating.-star1 {
//  background-position: -42px 0px; 
//}

#extFeedbackModal .modal-header .title {
  max-height: 60px;
  overflow: hidden;
  margin: 0;
  font-size: 20px;
  line-height: normal; 
}

.vote-panel__buttons {
  text-align: center;
  margin: 15px 0; 
}

.vote-panel__button {
  min-width: 25%;
  font-size: 16px;
  padding: 10px; 
}

.vote-panel__hr {
  margin: 15px 0; 
}

.vote-panel__addtokb {
  display: inline-block;
  padding-left: 19px;
  background: url("@{ra-image-path}icon_kb.png") no-repeat 0 3px; 
}

.vote-panel__looking {
  display: inline-block;
  padding-left: 21px;
  background: url("@{ra-image-path}justlooking.png") no-repeat 0 2px; 
}

.add-ext-form__label {
  display: block; 
}

textarea.add-ext-form__text {
  height: 100px; 
}

textarea.rake-file__url {
  height: 58px; 
}

.add-ext-form__saved {
  margin-right: 15px; 
}


/* Upland Branding */
#header-help-icon, #header-language-icon {
  margin-right: 15px;
  //color: var(--light);
  text-decoration: none;
  font-size: 20px;
  text-shadow: none;
}

@media (max-width: 450px) {
  #header-help-icon {
    display: none;
  }
  #header-language-icon {
    display: none;
  }
}

.ln1 .caret {
  border-top:4px solid var(--frosty);
  transform: rotate(-180deg);
}

#header-language-icon .caret {
  padding-top: 10px;
}

#header-language-icon:active, #header-language-icon:focus, #header-language-icon:hover {
  text-decoration: none;
}

.menu-emailaddress {
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 0.2px;
  color: @gray-dark;
}

.language-menu-div {
  min-width: 175px;
}

.language-menu {
  min-width: 175px;
  max-height: 600px;
  overflow: auto;
}

.language-header {
  font-weight: bold;
  font-size: 14px;
  display: block;
  padding: 5px 10px;
}

.selected-language {
  background-color: var(--blue-10);
}

.language-link-list > li > a {
  display: block;
  padding: 5px 10px;
}


.result-summary__list > li ul.actions {
  display: none;
  position: absolute;
  right: 15px;
  margin: 0;
  padding: 0 15px 0 30px;
  background-color: var(--light);
  list-style: none;
}
.result-summary__list > li:hover ul.actions {
  display: block;
}

.result-summary__list ul.actions > li {
  display: inline-block;
  opacity: 0.9;
}

.result-summary__list ul.actions > li:hover {
  display: inline-block;
  opacity: 1.0;
}

.solution-list > li ul.actions {
  display: none;
  position: absolute;
  right: 15px;
  margin: 0;
  padding: 0 15px 0 30px;
  background-color: var(--light);
  list-style: none;
  z-index: 1;
}
.solution-list > li:hover ul.actions {
  display: block;
}

.solution-list ul.actions > li {
  display: inline-block;
  opacity: 0.9;
}

.solution-list ul.actions > li:hover {
  display: inline-block;
  opacity: 1.0;
}



/* Divya's New NavBar Styles go here */
.SS-nav {
  background: var(--cool-gray-05);
  margin: 0px;
  padding: 9px 2px;
  height: 100vh;
}
.SS-nav li{
  padding: 10px;
}
.nav-stacked>li+li {
  margin-top: -20px;
  margin-left: 0;
}

.SS-menu {
  display: block;
  transition: left ease-in-out 0.3s;
}
.SS-menu >ul {
  position: absolute;
  z-index: 2;
  top: 0;
  width: 120%;
  height: 208vh;
  margin: 0px;
  padding: 5px;
  margin-top: -15px;
  transition: left ease-in-out 0.3s;
}
.SS-menu >ul.left {
  background: var(--cool-gray-05);
  display: none;
  left: -400px;
  transition: width 0.3s ease;
}

.SS-menu >ul.visible.left {
  background: var(--cool-gray-05);
  display: block;
  left: 80px;
  transition: width 0.3s ease;
  border-right: 2px solid var(--sand);
  height: 100vh;
  width:150px;
}
.SS-menu >div.right {
  background: #6B1919;
  right: -250px;
}
.SS-menu >ul >.menu-item {
  float: left;
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  padding: 9px;
  cursor: pointer;
  color: @dark;
}
.SS-menu >ul >.menu-item > span{
  padding: 13px 16px;
  font-size: 14px;
  line-height: 1.3333333;
  border-radius: 6px;
}
.SS-menu >ul >.menu-item:hover {
  color: #0B2161;
}
.SS-menu>ul>li>a{
  position: relative;
  display: block;
  margin-left: -15px;
  padding: 14.6px 5.4px;
}

.SS-myModal {
  top:35%;
  right:0%;
  outline: none;

}

.SS-myModal .modal-content{
  min-width: 550px;
}
.content {
  max-width: 500px;
  padding: 50px;
}
.btn-sidemenu{
  padding: 10px;
}
.SS-myModalText {
  text-align: center;
  font-size: 10px;
}
.SS-moveRight{
  float:right;
  margin-top:-1px;

}
.SS-myModal.modal.fade:not(.in).left .modal-dialog {
  -webkit-transform: translate3d(-125%, 0, 0);
  transform: translate3d(-125%, 0, 0);
}
.SS-movepinRight{
  float:right;
  margin-top:-10px;
  margin-right: -12px;
}
.sscontainer{
  padding-left: 0px;
  margin-left: 0px;
}
#socialsupportsidenav{
  width: 70px;
  margin-top: -15px;
  height: 100%;
}
.glyphicon-heart{
  color: #ff0000;
}
.glyphicon-bell{
  color: var(--gray-50);
}
.fa-question-circle{
  color: #0066ff;
}
#socialsupportsidenav .glyphicon-book{
  color: var(--green-70);
}
.fa-check{
  color: var(--green-70);
}
.fa-envelope{
  color:var(--yellow-30);
}
.fa-users{
  color: #ff6600;
}
.modal-header,.modal-footer{
  border: none;
}
.modal-header{
  padding:1px;
}
.modal-body {
  padding:1px;
}
@media only screen and (max-width : 990px){

  .SS-menu{
    float:left;
  }
  .SS-menu a{
    width:30px;

  }


}
@media only screen and (max-width : 480px){
  .SS-menu a{ width:30px; 
}
  float:left;
}
.modal-header {
  padding: 15px;
  border-bottom: 1px solid var(--gray-05);
}
.modal-header .modal-title, .modal-header .title {
  font-size: 1.4em;
  margin: 0;
}
.modal-header .close {
  font-size: 24px;
}
.modal-body {
  position: relative;
  max-height: 400px;
  padding: 15px;
  overflow-y: auto;
}
form {
  margin: 0;
}
.add-ext-form__label {
  display: block;
}
input[type=text].add-ext-form__input {
  box-sizing: border-box;
  width: 100%;
}

.add-ext-form__text {
  box-sizing: border-box;
  width: 100%;
  height: 100px;
}

.add-ext-form__saved {
  margin-right: 15px;
}
.text-right {
  text-align: right;
  margin-top: 20px;
}

#favoriteToolBar {
  padding-left: revert;
  text-align: left;
}

div.dialogContainer
{
  background-color: @gray-lighter;
  border-radius: 5px 5px 5px 5px;
  clear: both;
  padding: 10px;
  width: 920px;
  margin: 10px auto;
}

div.dialogContent
{
  background-color: var(--light);
  border-radius: 5px 5px 5px 5px;
  padding: 17px 15px 5px;
  position: relative;
}

div.dialogContent h1
{
  font-size: 15px;
  color: #212C5F;
  margin-bottom: 10px;
}

div.dialogContent div.windowButtons
{
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
}

div.dialogContent div.windowButtons a
{
  text-decoration: none;
}

div.dialogContent label
{
  display: inline-block;
  padding: 5px 10px 5px 0;
}

div.dialogContent input[type="text"]
{
  font-family: Arial,sans-serif;
  color: @brand-gray;
}

div.dialogContent textarea
{
  font-family: Arial,sans-serif;
  color: @brand-gray;
  font-size: 1em;
}

div.dialogContent div.dialogButtons
{
  margin: 10px 0;
  float: right;
}
.result-summary__sharehead-icon {
  margin-right: 5px;
  width: 33px;
  color: @brand-primary;
  vertical-align: bottom;
  background-image: url("@{ra-image-path}jira.png");
  background-color: transparent;
  background-repeat: no-repeat;
  display: inline-block;
}
.view-link > div.preview-pane {
  display:none;
}
.solution-list .preview-pane {
  background: var(--blue-05);
  border: 1px solid var(--gray-20);
  border-radius: 4px;
  padding: 10px;
  max-height: 450px;
  overflow: auto;
  margin: 5px 15px 0 60px;
}

.result-summary .preview-pane {
  margin-left: 50px;
}

div.preview-pane > div.content {
  padding: 5px;
}
div.preview-pane > div.content > div.solution {
  width: 550px;
}

.muted {
  color: #999999;
}

.kbDidYouMean {
  color: @state-danger-text;
  font-weight: bold;
  margin-bottom: 5px;
}

.kbDidYouMean a {
  font-weight: normal;
  font-style: italic;
}

.commDidYouMean {
  color: var(--purple-70);
  font-weight: bold;
  margin-bottom: 5px;
}

.commDidYouMean a {
  font-weight: normal;
  font-style: italic;
}

.result-summary__head-jiraicon {
  margin-right: 5px;
  width: 33px;
  color: @brand-primary;
  vertical-align: bottom;
  background: url("@{ra-image-path}jira.png") no-repeat 0 3px; 
}


.result-summary__head-conficon {
  margin-right: 5px;
  width: 33px;
  color: @brand-primary;
  vertical-align: bottom;
  background: url("@{ra-image-path}confluence.png") no-repeat 0 3px; 
}

.result-summary__head-sficon {
  margin-right: 5px;
  width: 33px;
  color: @brand-primary;
  vertical-align: bottom;
  background: url("@{ra-image-path}salesforce.png") no-repeat 0 3px; 
}


.fa-cloud {
  content: url("@{ra-image-path}confluence.png");
}

.fa-rss {
  content: url("@{ra-image-path}jira.png");
}

.fa-rss-square {
  content: url("@{ra-image-path}sharepoint.png");
}

.fa-servicenow {
  content: url("@{ra-image-path}servicenow.png")
}

.fa-salesforce
{
  content: url("@{ra-image-path}salesforce.png")
}

#didyouknow-sa-container {
  margin-top: -1px;
}

#workbenchresultstable {
  font-size: 14px;
  background-color: var(--light);
}

#incidentSummary {
  font-size: 14px;
  background-color: var(--light);
}

//.row {
//  margin-left : -10px;
//}



.ribbon-menu {
  position: fixed;
  top: 100px;
  left: 0;
  list-style-type: none;
  margin: 0px;
  padding: 0px;
  background: var(--light);
  z-index:10;
  overflow:hidden;
  box-shadow: 2px 0 18px rgba(0, 0, 0, 0.26);
  transition: all 0.1s ease-in-out;
}

.ribbon-menu-docked {
  top: 55px;
}

.ribbon-menu li a {
  display: block;
  height: 3em;
  line-height: 3em;
  vertical-align: middle;
  color: var(--gray-50);
  position: relative;
  transition: background 0.1s ease-in-out;
  text-decoration: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-bottom: 1px solid var(--gray-20);
}

.ribbon-menu li a:hover,
.ribbon-menu li:first-child a,
.ribbon-menu li span:hover {
  background: var(--gray-05);
  color: @brand-highlight;
}

.ribbon-menu .open {
  min-width: 12em;
  width:auto;
  height:auto;
}

@media all and (max-width: 500px) {

  .ribbon-menu {
    height:5em;
    width:100%;
  }
  .ribbon-menu li{
    display:inline-block;
    float:left;
  }

  .open{
    width:100%;
    height:auto;
  }

  .para{
    padding-left:5px;
  }
}

@media screen and (max-height: 34em){
  .ribbon-menu li {
    font-size:14px;
  }
}

@media screen and (max-height: 34em) and (max-width: 500px){
  .ribbon-menu{
    height:3.5em;
  }
}

.ribbon-options-left {
  padding: 10px;
  width: 3em;
  text-align: center;
}

.ribbon-options-right {
  width: auto;
  padding-left: 2px;
  text-decoration: none;
  font-weight: bold;
  font-size: 14px;
}

.ribbon-option-search {
  color: @brand-primary;
}

.ribbon-option-favorites {
  color: var(--red-50);
}

.ribbon-option-announcements {
  color: #993300;
}

.ribbon-option-faqs {
  color: #0066ff;
}

.ribbon-option-popularsolutions {
  color: @brand-primary;
}

.ribbon-option-latestsolutions {
  color: @brand-primary;
}

.ribbon-option-recentsearches {
  color: @brand-primary;
}

.ribbon-option-mostviewedsolutions {
  color: #ff6600;
}

.ribbon-option-popularviews {
  color: @brand-primary;
}

.ribbon-option-inprogress {
  color: var(--green-70);
}

.ribbon-option-subscribedsolutions {
  color: var(--yellow-30);
}

.ribbon-modal-close {
  color: red;
}

.ribbon-option-bars {
  color: @brand-highlight;
}

.modal.fade:not(.in).left .modal-dialog {
  -webkit-transform: translate3d(-25%, 0, 0);
  transform: translate3d(-25%, 0, 0);
}

#ribbonModal {
  text-align: center;
  padding: 0!important;
}

#ribbonModal:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
}

#ribbonModal .modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}


@media (max-width: 450px) {
  .ribbon-menu {
    display: none;
  }
}



.tabbable-panel {
  border:1px solid @gray-lighter;
  padding: 10px;
}

.tabbable-line > .nav-tabs {
  border: none;
  margin: 0px;
}
.tabbable-line > .nav-tabs > li {
  margin-right: 2px;
}
.tabbable-line > .nav-tabs > li > a {

  font-size: 14px;
  font-weight: bold;
  border: 0;
  margin-right: 0;
  color: inherit;
}
.tabbable-line > .nav-tabs > li > a > i {
  color: var(--tan);
}
.tabbable-line > .nav-tabs > li.open, .tabbable-line > .nav-tabs > li:hover {
  border-bottom: 4px solid var(--gray-20);
}
.tabbable-line > .nav-tabs > li.open > a, .tabbable-line > .nav-tabs > li:hover > a {
  border: 0;
  background: none !important;
  color: @gray-dark;
}
.tabbable-line > .nav-tabs > li.open > a > i, .tabbable-line > .nav-tabs > li:hover > a > i {
  color: var(--tan);
}
.tabbable-line > .nav-tabs > li.open .dropdown-menu, .tabbable-line > .nav-tabs > li:hover .dropdown-menu {
  margin-top: 0px;
}
.tabbable-line > .nav-tabs > li.active {
  border-bottom: 4px solid @brand-primary;
  position: relative;
}
.tabbable-line > .nav-tabs > li.active > a {
  font-size: 14px;
  font-weight: bold;
  border: 0;
  color: inherit;
}
.tabbable-line > .nav-tabs > li.active > a > i {
  color: #404040;
}
.tabbable-line > .tab-content {
  margin-top: -3px;
  background-color: var(--light);
  border: 0;
  border-top: 1px solid @gray-lighter;
  padding: 15px 0;
}
.portlet .tabbable-line > .tab-content {
  padding-bottom: 0;
}

.tabbable-line.tabs-below > .nav-tabs > li {
  border-top: 4px solid transparent;
}
.tabbable-line.tabs-below > .nav-tabs > li > a {
  margin-top: 0;
}
.tabbable-line.tabs-below > .nav-tabs > li:hover {
  border-bottom: 0;
  border-top: 4px solid var(--tuna);
}
.tabbable-line.tabs-below > .nav-tabs > li.active {
  margin-bottom: -2px;
  border-bottom: 0;
  border-top: 4px solid var(--salmon);
}
.tabbable-line.tabs-below > .tab-content {
  margin-top: -10px;
  border-top: 0;
  border-bottom: 1px solid @gray-lighter;
  padding-bottom: 15px;
}

.my-top-views-time-range > .active {
  border-bottom: 4px solid @brand-primary;
  position: relative;
}

#topMyViewsList {
  margin:5px;
  padding:2px;
}

.my-top-views-time-range {
  border-bottom: 1px solid @gray-lighter;
}

.my-top-views-time-range-tabs {
  color: #555;
  font-size: 14px;
  font-weight: bold;
  margin: 0 5px;
}

div.modal-body aside.aside div.my-top-views-content div.my-top-views-time-range span.active.my-top-views-time-range-tabs {
  margin: 30px;
}

table#workbenchresultstable tr td:nth-child(2){display:none; 
}
table#workbenchresultstable tr th:nth-child(2){display:none; 
}

.logo_title {
  //font-family: @font-family-base;
  font-size: 1.25rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: @brand-primary;
  display: inline-block;
  padding-left: 1rem;
  //text-decoration: none;
}

#copy-solution-area {
  width:99%;
  margin-bottom: 5px;
  border:solid 1px @brand-primary;
}

.mce-content-body li{
  list-style-position: inside;
}

.mce-content-body ul li p,
.mce-content-body ol li p {
  display: inline-block;
}

.social-flagged {
  font-size: 20px;
  background-color: var(--red-50);
  padding: 3px 10px;
  border-radius: 2px;
  color: var(--light);
  position: relative;
}

.slick-prev::before, .slick-next::before {
  color: @brand-highlight !important;
}

.slick-track {
  margin-left: 0px !important;
}

.slick-prev {
  left: -22px !important;
}

.slick-next {
  right: -10px !important;
}

@media (max-width: 767px) {
  body {
    padding-right: 0px;
    padding-left: 0px;
  }

  .slick-prev {
    left: -28px !important;
  }

  .slick-next {
    right: -20px !important;
  }

  .result-sources__ss .slick-prev {
    left: -43px !important;
  }

  .result-sources__ss .slick-next {
    right: -35px !important;
  }
}

.alltab_taxonomy_clicked {
  font-weight: 500;
}

/* Integrations CSS */
#integrationTicketListResults {
  width: 100%;
}

#integrationOpenTicketHeader,#integrationTicketHeader,#integrationAgentTicketInfoHeader {
  background-color: @brand-primary;
  color: var(--light);
  padding: 15px;
  margin: 0 0 10px;
}

#integrationTicketLoading,#integrationOpenTicketLoading,#integrationAgentTicketInfoLoading {
  position: fixed;
  top: 35%;
  left: 50%;
  display: block;
}

.form-control {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  height: 40px;
  border-radius: 2px;
  border: solid 1px var(--gray-20);
}

.form-control:focus {
  border: solid 2px @brand-highlight;
}

.form-control[readonly] {
  background-color: transparent;
}

.form-control[readonly]:hover {
  cursor: not-allowed;
}

.text-success {
  color: @state-success-text;
}

.text-danger {
  color: var(--red-50);
}

//.btn-success {
//  color: var(--light);
//  background-color: #5cb85c;
//  border-color: #4cae4c;
//}
//
//.btn-success:hover, .btn-success:active, .btn-success:focus, .btn-success:active:focus {
//  -webkit-box-shadow: none;
//  box-shadow: none;
//  color: var(--light);
//  background-color: #449d44;
//  border-color: #398439;
//}

.btn-danger {
  color: var(--light);
  background-color: var(--red-50);
}

.btn-danger:hover, .btn-danger:active, .btn-danger:focus, .btn-danger:active:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  color: var(--light);
  background-color: var(--red-70);
}

.input-group-btn > button {
  height: 40px;
}

.bootstrap-select > .dropdown-toggle {
  height: 40px;
}

.bootstrap-select > .dropdown-toggle:hover {
  background-color: var(--light);
}

.bootstrap-select > .dropdown-toggle:focus {
  background-color: var(--light);
  border: 2px solid @brand-highlight;
}

.btn-group.open .dropdown-toggle,
.btn-group.open .dropdown-toggle:hover,
.btn-group.open .dropdown-toggle:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.bootstrap-select.btn-group .dropdown-menu {
  max-height: 450px !important;
}

.bootstrap-select.btn-group.open .dropdown-toggle,
.bootstrap-select.btn-group.open .dropdown-toggle:hover,
.bootstrap-select.btn-group.open .dropdown-toggle:focus {
  background-color: transparent;
  border: 2px solid @brand-highlight;
}

.bootstrap-select.btn-group .dropdown-menu li.selected {
  background-color: var(--blue-10);
}

.bootstrap-select.btn-group .dropdown-menu li a {
  padding: 0 15px;
  line-height: 40px;
}

.dropdown-menu > li > a:focus, .dropdown-menu > li > a:hover {
  background-color: var(--gray-05);
}

.dropdown-menu > li.selected > a:focus, .dropdown-menu > li.selected > a:hover {
  background-color: var(--blue-10);
}

.alert-info {
  color: @brand-primary;
}

.alert-success {
  color: var(--green-70);
}

.alert-warning {
  color: var(--yellow-50);
}

.alert-danger, .alert-danger .alert-link {
  color: var(--red-50);
}

div.loading {
  text-align: center;
}

div#postDetails > div.form-group.has-float-label:focus-within > label {
  color: @brand-highlight;
}



.hor-cen-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.hor-cen-list {
  text-align: center;
}

.hor-cen-list > li {
  display: inline-block;
  margin: 5px 5px;
}

.btn {
  //font-weight: 600;
  //font-stretch: normal;
  //font-style: normal;
  //line-height: 1.43;
  //letter-spacing: 1.25px;
}


.quick-links-menu .link-list > li > a {
  padding: 0;
  color: var(--gray-40);
}

.quick-links-menu .link-list > li > a:hover {
  color: @brand-highlight;
}

.nav .open > a, .nav .open > a:focus, .nav .open > a:hover {
  background-color: transparent;
}

div#mainContent {
  min-height: calc(100vh - 390px);
}

div#contentTop, div#solutionTop {
  min-height: calc(100vh - 355px);
}

.sticky-content {
  min-height: calc(100vh - 650px);
}
.sticky-content-lg {
  min-height: calc(100vh - 355px);
}

.discussion-container {
  min-height: calc(100vh - 416px);
}

.document-container {
  min-height: calc(100vh - 385px);
}

.sscontainer {
  min-height: calc(100vh - 352px);
}

.results-bg-white {
  background-color: var(--light);
  border-radius: 4px;
  border: 1px solid var(--gray-05);
  margin: 2rem 2rem 4rem;
  box-shadow: 0px 5px 5px rgba(0,0,0,0.08);
}

.results-bg-white #resultTabsList {
  padding-top: 10px;
}

#profileSummary {
  background-color: var(--light);
  border-radius: 4px;
  border: 1px solid var(--gray-05);
  padding: 20px;
  box-shadow: 0px 5px 5px rgba(0,0,0,0.08);
  margin: 24px 0;
}

#profileSummary .section {
  border: none;
  box-shadow: none;
  padding: 0;
  margin: 0;
}

.has-float-label .bootstrap-select > button {
  border: solid 1px var(--gray-20);
}

.title-line-through {
  font-family: @font-family-serif;
  font-weight: normal;
  font-size: 1.75rem;
  margin-bottom: 15px;
  display: inline;
  position: relative;
  top: -22px;
  left: 30px;
  padding: 0 15px;
  background-color: var(--cool-gray-05);
}

.title-line-through-sep {
  display: block;
  border-bottom: 1px solid @brand-secondary;
  margin-top: 1rem;
}

h1.title-line-through {
  top: -17px;
}

.has-float-label .bootstrap-select > button {
  border-radius: 2px !important;
}

.solution-content .fieldHeader {
  font-size: 1.4em;
}

.solution-container {
  margin-left: 10px;
  margin-right: -10px;
  margin-bottom: 3rem;
}

.discussion-container {
  margin-left: 15px;
  width: calc(75% - 15px);
}

.document-container {
  margin-left: 15px;
  width: calc(75% - 15px);
}

.solution-container, .portlet-container, .ask-community-container, .discussion-container, #communityBrowser, .document-container {
  background-color: var(--light);
  border-radius: 4px;
  border: 1px solid var(--gray-05);
  padding: 20px;
  -webkit-box-shadow: 0px 5px 5px rgba(0,0,0,0.08);
  box-shadow: 0px 5px 5px rgba(0,0,0,0.08);
}

@media (max-width: 991px) {
  .discussion-container, .document-container {
    width: calc(100% - 30px);
    margin-bottom: 20px;
  }

  .solution-container {
    width: calc(100% - 10px);
    margin-bottom: 20px;
  }
}

#suggestedHelp {
  margin-top: 30px;
}

.view-link__hit-summary + div > span:first-of-type {
  color: @gray-dark;
}

.line-sep {
  display: block;
  border-bottom: 1px solid var(--gray-20);
  margin-bottom: 30px;
}

#kbResults .filter-col > .filter,
#commResults .filter-col > .filter {
  margin: 0 -15px;
}

#kbResults .filter-col > .filter:not(:last-child),
#commResults .filter-col > .filter:not(:last-child) {
  border-bottom: 1px solid var(--gray-20);
}

h4.title {
  font-size: 1.4em;
}

.filter-type__author .filter {
  padding: 0 0 20px 0;
}

.sscontainer #view-btr-link > a {
  margin-right: -10px;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-10 {
  margin-bottom: 10px;
}

.ml-5 {
  margin-left: 5px;
}

.mt-20 {
  margin-top: 20px;
}

.pr-0 {
  padding-right: 0;
}

.pr-20 {
  padding-right: 20px;
}

.pr-30 {
  padding-right: 30px;
}

.pl-0 {
  padding-left: 0;
}

.pl-20 {
  padding-left: 20px;
}

.container-fw {
  width: 100%;
}

.ribbon-menu {
  display: none;
}


#view-btr-link {
  //margin-top: -2rem;
}

@media (max-width: 767px) {
  .section {
    margin-top: 35px;
  }

  #view-btr-link {
    margin-top: 35px;
  }

  .results-bg-white {
    margin-top: 30px;
  }

  #resultTabsList {
    margin-left: -5px;
  }

  #kbResults,
  #commResults,
  #rakeResults,
  #confluenceResults {
    margin-left: 5px;
  }

  #communityBrowser, #titlePreferencesPage, .btn-row {
    margin-top: 30px;
  }
}

.popular-row .section,
#contentTop .section {
  min-height: 300px;
}

.results-bg-white .row {
  margin-left: -2px;
}


.progress-wrap {
  max-width: 100% !important;
}

.has-float-label .bootstrap-select > button > div.filter-option > div.filter-option-inner > div.filter-option-inner-inner {
  padding-top: 3px;
}

.has-float-label .bootstrap-select.dropdown .dropdown-menu li.selected.active > a,
.has-float-label .bootstrap-select.dropdown .dropdown-menu li.selected > a {
  background-color: var(--blue-10);
}

.has-float-label .bootstrap-select.dropdown .dropdown-menu li.active > a {
  background-color: var(--gray-05);
}

.has-float-label .bootstrap-select.dropdown .dropdown-menu li > a {
  line-height: 40px;
  color: @gray-dark;
}

.has-float-label .bootstrap-select.dropdown .dropdown-menu {
  max-height: 450px !important;
}

#favoriteFilter .pagination {
  margin: 0;
}

.my-posts__filter-row .has-float-label .bootstrap-select.dropdown {
  width: 100%;
}

.resolve-btn {
  text-decoration: none;
}

.resolved-mark-style {
  font-size: 1.75em;
  color: green;
}

.unresolved-mark-style {
  font-size: 1.75em;
  color: grey;
}

.resolved-text {
  font-size: 0.80em;
  font-weight:bold;
}

.menu {
  background-color: var(--gray-again);
  position: absolute;
  z-index: 100;
  max-height: 45vh;
  max-width: 60vw;
  overflow: overlay;
}

.menu-item {
  cursor: default;
  padding: 1rem;
  font-size: small;
}

.menu-item.selected {
  background-color: slateGray;
  color: var(--light);
}

.menu-item:hover:not(.selected) {
  background-color: var(--tan);
}

.comment-href a {
  text-decoration: none;
  color: @dark !important;
  pointer-events: none;
  cursor: default;
}

.comment-href a:hover {
  text-decoration: none;
  color: @dark !important;
}

.comment-href a:active {
  text-decoration: none;
  color: @dark !important;
}

#googleDriveIcon {
  vertical-align: top;
}

div.view-link__sub-line span.label-default {
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: var(--light);
  border: solid 1px var(--gray-60);
  color: var(--gray-60);
  position: relative;
  top: -2px;
}

#shareOptionsDialog .modal-dialog {
  width: 400px;
  margin: 275px auto;
}

#shareOptionsDialog .modal-header {
  padding: 10px 0;
}

#shareOptionsDialog .modal-header h3 {
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  margin-top: 10px
}

#shareOptionsDialog .modal-body {
  text-align: center;
  padding: 10px;
}

/* AIChatApp (Chat Container) */

.ai-chat-app {
  font-family: Arial, sans-serif;
  background-color: var(--light);
  border: 1px solid var(--gray-light-c);
  padding: 10px;
  max-width: 100%;
  margin: 10px 0 15px 0;
}

.ai-chat-message-container {
  max-height: 300px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}

.ai-chat-message-item {
  display: flex;
  justify-content: end;
}

.ai-chat-message-item.bot {
  justify-content: start;
  align-items: end;
}

.ai-chat-message-citations-container {
  display: flex;
  margin-top: 10px;
  border-top: 1px solid var(--gray-light-c);
  padding-top: 10px;
}

.ai-chat-message-citations-container span:nth-child(1) {
  white-space: nowrap;
}

.ai-chat-message-citations-list {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.ai-chat-message-citation a {
  margin-left: 5px;
  width: 100%;
  text-overflow: ellipsis;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
}

.ai-chat-message-citation-seperator{
  display: inline-block;
  color: @brand-primary;
  overflow: hidden;
}

@media (max-width: 768px) {
  .ai-chat-message-citations-container {
    flex-direction: column;
  }
  .ai-chat-message-citation a {
    margin-left: 0;
  }
}

.ai-chat-message-input {
  margin-top: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.ai-chat-message-input .disclaimer-message {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 10px 20px 0 20px;
  gap: 5px;
  color: var(--dark);
}

.ai-chat-message-input .disclaimer-message span {
  font-weight: bold;
}

.ai-chat-message-input .disclaimer-message p {
  margin: 0;
}

.ai-chat-message-input .disclaimer-message p a {
  margin-left: 5px;
}

.suggestion-container {
  width: 100%;
  padding: 10px 0;
  border-top: 1px solid var(--gray-light-c);
}

.ai-chat-suggestion {
  width: fit-content;
  display: inline-block;
  padding: 10px;
  margin: 5px;
  background-color: @brand-primary;
  color: var(--light);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  word-wrap: break-word;
  text-align: left;
}

.ai-chat-suggestion div {
  display: flex;
  align-items: center;
}

.ai-chat-suggestion .glyphicon-question-sign {
  margin: 0 5px 2px 0;
}

.ai-chat-message-input .input-container {
  display: flex;
  width: 100%;
}

.ai-chat-message-input input[type="text"] {
  flex: 1;
  padding: 8px;
  border: 1px solid var(--gray-light-c);
  border-right: none;
}

.ai-chat-message-input button {
  color: #007bff;
  border: 1px solid var(--gray-light-c);
  border-left: none;
  background: none;
  padding: 8px 12px;
  cursor: pointer;
}

.ai-chat-message-input button:hover {
  color: @brand-primary;
}

.ai-chat-message-input button[disabled] {
  cursor: not-allowed;
  color: #88c2ff;
}

/* AIChatMessage (Chat Message) */
.ai-chat-message {
  padding: 10px;
  margin: 5px;
  background-color: var(--light);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  word-wrap: break-word;
  text-align: left;
  max-width: 80%;
  position: relative;
}

.ai-chat-message.user {
  background-color: @brand-primary;
  color: var(--light);
  float: right;
  margin-right: 12px;
}

.ai-chat-message.user:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 5px solid @brand-primary;
  border-right: 5px solid transparent;
  border-top: 4px solid @brand-primary;
  border-bottom: 4px solid transparent;
  right: -10px;
  bottom: 3px;
}

.ai-chat-message.bot {
  /* background-color: #f6f6f6; */
  border: 1px solid var(--gray-light-c);
  color: #444;
  float: left;
  padding-bottom: 5px;
  margin-left: 10px;
}

.ai-chat-message.bot:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 5px solid transparent;
  border-right: 5px solid var(--gray-light-c);
  border-top: 4px solid var(--gray-light-c);
  border-bottom: 4px solid transparent;
  left: -10px;
  bottom: 3px;
}

.ai-chat-message.bot:after {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 4px solid transparent;
  border-right: 4px solid var(--light);
  border-top: 3px solid var(--light);
  border-bottom: 3px solid transparent;
  left: -7px;
  bottom: 4px;
}

.ai-chat-hr {
  margin: 5px 0;
  border: none;
  border-top: 1px solid var(--gray-light-c);
}

/* AIChatMessage Buttons (Like and Dislike) */
.ai-chat-message-buttons {
  display: flex;
  justify-content: flex-start;
  margin: 5px 0 0 -10px;
}

.ai-chat-message-buttons button {
  background: none;
  color: var(--ai-chat-button);
  border: none;
  padding: 8px 8px;
  cursor: pointer;
  margin-left: 5px;
}

.ai-chat-message-buttons button:hover {
  color: @brand-primary;
}

.ai-chat-message-buttons button[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
  background-color: var(--ai-chat-button-disabled);
  color: @gray-light;
  border: 1px solid @gray-light-c;
}

@keyframes spin {
  0% { transform: rotate(0deg); 
}
  100% { transform: rotate(360deg); 
}
}

.ai-loading-spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
}

.typing-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  padding-top: 20px;
  padding-bottom: 15px;
}

.typing-indicator-dot {
  width: 10px;
  height: 10px;
  background-color: @brand-primary;
  border-radius: 50%;
  margin: 0 5px;
  animation: dot-blink 1s infinite;
}

@keyframes dot-blink {
  0%, 20% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

a.ec-deep-link {
  text-decoration: none;
}

a.ec-deep-link .deep-link-icon {
  opacity: 0;
}

.solution-content .expand-collapse:hover a.ec-deep-link .deep-link-icon {
  opacity: 1 !important;
}

/* === END UPLAND === */
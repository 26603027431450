.ra-check {
  display: inline-block;
  line-height: 2;
  width: 100%;
  word-wrap: break-word;
  cursor: pointer;
}
.ra-check__box {
  display: inline-block;
  cursor: default;
  height: 18px;
  width: 18px;
  border: 2px solid @gray-dark;
  position: relative;
  top: 3px;
}
.ra-check:hover > .ra-check__box {
  border: 2px solid @brand-primary;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.ra-check.checked > .ra-check__box {
  background-image: url('@{ra-image-path}checkmark.png');
  background-repeat: no-repeat;
  background-position: -5px -3px;
}
.ra-check:hover .ra-check__label {
  &, .ra-check.checked .ra-check__label {
    color: @brand-primary;
    text-decoration: none;
  }
  &:after{
    display: block;
    content: " ";
    height: 2px;
    margin-top: -2px;
    background-image: @brand-gradient-smooth;
  }
}
.ra-check.disabled > .ra-check__box {
  background-color: var(--gray-lighter);
}
.ra-check.disabled:hover > .ra-check__box {
  border: 2px solid var(--gray-50);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.ra-check__label {
  padding-left: 5px;
  color: @gray-darker;
  text-decoration: none;
}
.announce {
  position: relative;
  padding: 15px 15px 10px 15px;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
  border-radius: 4px;
  border: 1px solid var(--gray-05);
  box-shadow: 0px 5px 5px rgba(0,0,0,0.08);
  background-color: var(--light);
}
.announce.-new {
  border-color: @alert-warning-border;
  background-color: @alert-warning-bg;
}
.announce__head { }
.announce.-new .announce__head {
  border-color: @alert-warning-border;
}
.announce__title {
  padding: 5px;
  margin-left: 40px;
  padding: 0 10px 5px 10px;
  //font-family: @font-family-sans-serif;
  font-weight: normal;
  font-size: 1.4em;
  line-height: normal;
}
.announce.-new .announce__title {
  color: var(--state-warning-text);
  background-color: var(--state-warning-bg);
}
.announce__icon {
  position: absolute;
  width: 40px;
  float: left;
  text-align: center;
  font-size: 25px;
  color: var(--pink-sand);
}
.announce.-new .announce__icon {
  color: var(--state-warning-text);
}
.announce__badge {
  display: none;
  position: absolute;
  top: 3px;
  left: 23px;
  background-color: var(--red-50);
  font-size: 11px;
  padding: 3px 6px;
}
.announce__arrow {
  position: absolute;
  right: 20px;
  top: 22px;
  color: var(--gray-40);
}
.announce__toggle-link {
  display: block;
  color: var(--gray-70);
}
.announce__toggle-link:hover, .announce__toggle-link:active, .announce__toggle-link:focus {
  outline: none;
  color: @gray-dark;
  text-decoration: none;
}
.announce__toggle-link .announce__arrow.-down {
  display: none;
}
.announce__toggle-link .announce__arrow.-up {
  display: block;
}
.announce__toggle-link.collapsed .announce__arrow.-up {
  display: none;
}
.announce__toggle-link.collapsed .announce__arrow.-down {
  display: block;
}
.announce.-new .announce__toggle-link {
  color: var(--state-warning-text);
}
.announce__toggle-link:hover .announce__title { }
.announce__toggle-link:hover .announce__icon {
  color: var(--gray-20);
}
.announce.-new .announce__toggle-link:hover .announce__title {
  background-color: var(--sunbeam);
}
.announce.-new .announce__toggle-link:hover .announce__icon,
.announce.-new .announce__toggle-link:hover .announce__arrow {
  color: #785f33;
}
.announce__list {
  list-style: none;
  margin: 15px;
  padding: 0;
}
.announce__item {
  padding-left: 7px;
  border-left: 3px solid var(--pink-sand);
  margin-bottom: 10px;
}
.announce__item.-new {
  border-left-color: var(--red-50);
}

.announce__list li, div#faqItems li{
  list-style-position: inside;
}
.announce__list ul,
div#faqItems .collapse-list__item ul{list-style-type:disc}

.announce__list ul ul,
div#faqItems .collapse-list__item  ul ul{list-style-type:circle}

.announce__list ul ul ul,
div#faqItems .collapse-list__item  ul ul ul{list-style-type:square}


.announce__list ol,
div#faqItems ol{list-style-type:decimal}

.announce__list ol ol,
div#faqItems ol ol{list-style-type:lower-alpha}

.announce__list ol ol ol,
div#faqItems ol ol ol{list-style-type:lower-greek}

.announce__list ol ol ol ol,
div#faqItems ol ol ol ol{list-style-type:lower-roman}

.announce__list ol ol ol ol ol,
div#faqItems ol ol ol ol ol{list-style-type:upper-alpha}

.announce__list ol ol ol ol ol ol,
div#faqItems ol ol ol ol ol ol{list-style-type:upper-roman}
